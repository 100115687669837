import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MyCarousel({ handleCaroselAnimate }) {

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="next-arrow d-none d-lg-block"
        onClick={onClick}>
        <img className="pt-1 next-icon" src="/static/assets/NextArrow.svg" alt="next-arrow"/>
        </div>
      
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="prev-arrow d-none d-lg-block"
        onClick={onClick}>
      <img className="pt-1 prev-icon" src="/static/assets/PrevArrow.svg" alt="prev-arrow"/>
        </div>
      
    );
  } 

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 0,
          // infinite: true,
        },
      },
    ],
  };
  const cards = [
    {
      id: 1,
      title: "Welcome",
      content: "I'm here to help you while you shop.",
    },
    {
      id: 2,
      title: "Outfit For Any Occasion",
      content:
        "What should I wear for a winter wedding in New York City? ",
    },
    {
      id: 3,
      title: "Product Discovery ",
      content: "Do you have any large Polo shirts in white? ",
    },
    {
      id: 4,
      title: "Timeless Gifting",
      content: "What gift should I get my significant other?",
    },
    {
      id: 5,
      title: "Brand History",
      content:
        "What's the orgin of the Polo Bear?",
    },
  ];

  return (
    <div className="carousel-container slider-container container-fluid px-0 col-12 col-md-10 col-xl-9">
      <Slider {...settings}>
        {cards.map((card, i) => (
          <div>
            <div className="headerContainer">
              <span className="headerContainer_title me-1">{card?.title}</span>
              {i > 0 && (
                <button
                  className="headerContainer_tryBtn btn--outline"
                  onClick={() => handleCaroselAnimate(card?.content)}
                >
                  Try it
                </button>
              )}
            </div>
            <div className="cardContent">
              <p className="cardContent-item">{card?.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MyCarousel;
