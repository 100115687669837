import React, { useEffect, useState } from "react";
import TextOnly from "../Components/StreamingComponents/TextOnly";
import SpecificProductDetails from "../Components/StreamingComponents/SpecificProductDetails";
import BrandingCard from "../Components/StreamingComponents/BrandingCard";
import SingleCategoryList from "../Components/StreamingComponents/SingleCategoryList";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import {
  apiError,
  conversationList,
  storeChat,
} from "../Services/Redux/Reducers/chatSlice";
import {
  addchat,
  deleteChat,
  uniqueSessionHistory,
} from "../Services/ApiServices/chatServices";
import { loader } from "../Services/Redux/Reducers/commonSlice";
import MultiCatTemplate from "../Components/MultiCatCardTemplate/MultiCatTemplate";
import LikeAndDislike from "./LikeAndDislike";
import { useMsal } from "@azure/msal-react";
import ChatHistoryFeedBack from "./ChatHistoryFeedBack";
import { useLocation } from "react-router-dom";
import ChatOperation from "./ChatOperation";
// import { sessionHistory } from "../Services/Redux/Reducers/chatSlice";

function ChatHistory(props) {
  const { accounts } = useMsal();
  const [shortName, setShortName] = useState("");
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [isScreenResponsive, SetIsScreenResponsive] = useState(false);
  const sessionList = useSelector((state) => state.chat.sessionHistory);
  const conList = useSelector((state) => state.chat.conversationList);
  const loading = useSelector((state) => state.common.isLoader);
  const dispatch = useDispatch();
  const isStore = useSelector((state) => state.chat.storeChat);

  const location = useLocation();

  const { userId, sessionId } = location.state;

  const storeMessage = () => {
    // const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
    const params = [userId, sessionId];
    // const params = [userId, JSON.stringify(sessionId)];
    // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
    uniqueSessionHistory(
      params,
      (response) => {
        // Chat History storing the messages
        setData(response.data.data);
      },
      (err) => {
        dispatch(apiError(err.message));
        dispatch(loader(false));
      }
    );
  };
  

  useEffect(() => {
    storeMessage();
    if (accounts.length !== 0) {
      if (accounts[0].name.includes(",")) {
        let firstname = accounts[0].name.split(",")[1];
        firstname = firstname.split(" ")[1];
        let lastname = accounts[0].name.split(",")[0];
        setName(firstname + " " + lastname);
        setShortName(firstname.charAt(0) + lastname.charAt(0));
      } else {
        let firstname = accounts[0].name;
        setName(firstname);
        setShortName(firstname.charAt(0));
      }
    }
  }, [accounts]);

  const handleValueChange = (childValue) => {
    props.onValueChange(childValue);
  };

  //iphone css fix
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (
      macosPlatforms.test(userAgent) ||
      windowsPlatforms.test(userAgent) ||
      (/android/.test(userAgent) && !safari)
    ) {
      SetIsScreenResponsive(true);
    }
  }, []);

  return (
    <div>
      <div className="d-flex flex-column">
        <div
          className={`homepage ${loading ? "homepageresize" : ""} 
        ${
          loading && isScreenResponsive
            ? "res_homepageresize"
            : isScreenResponsive
            ? "res_homepage"
            : ""
        }`}
          id="pageContainer"
        >
          <div className="page-container history-container">
            <div className="chatwindow">
              <div className="history-conversationlist chat_options">
                <div
                  className={`container container-fluid min-height px-0 px-md-3 ${
                    isScreenResponsive && "res_min-height"
                  }`}
                >
                  <div style={{ height: "80px" }}></div>
                  <div className="messageHistory">
                    {/* deleteChat api call */}
                    {/* <div>
        <button className="btn btn-primary" onClick={() => deleteChats()}>Delete</button>
      </div> */}
                    { 
                       data && data.length > 0 && data.map((con, index) => (
                        <div key={"history" + index}>
                          <div>
                            {/* <input type="checkbox" id={con.id} key={"history"+index} onClick={(e) => selectMessages(e)}/> */}
                            <div
                              className={`chat_msg ms-auto me-3 me-sm-1 fadeOutPromts`}
                            >
                              <div className="d-flex align-items-start ps-0">
                                {/* <div className="border border-1 user_bubble">
                                  {shortName}
                                </div> */}
                                <span className="badge-text">
                                  {con.question}
                                </span>
                              </div>
                            </div>
                          </div>
                          {Object.keys(con.response).length > 0 && (
                            <div>
                              {con &&
                                con.response?.responseType === "Error" &&
                                con.response?.error !==
                                  "Oops, Request cancelled" && (
                                  <div className="text-danger">
                                    {con.response.error}
                                  </div>
                                )}
                              {con &&
                                con.response?.responseType &&
                                con.response?.responseType === "TextOnly" && (
                                  <TextOnly
                                    index={index}
                                    responseType={con.response.responseType}
                                    text={con.response.responseText}
                                    conclusion={con.response.responseConclusion}
                                    delay={20}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick = {true}
                                    // onValueChange={handleValueChange}
                                    effects={false} 
                                  />
                                )}
                              {con &&
                                con.response?.responseType &&
                                con.response?.responseType ===
                                  "BrandingInformation" && (
                                  <BrandingCard
                                    index={index}
                                    text={con.response.responseText}
                                    branding={con.response.branding}
                                    conclusion={con.response.responseConclusion}
                                    delay={10}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick = {true}
                                    // onValueChange={handleValueChange}
                                    effects={false}
                                  />
                                )}
                              {con &&
                                con.response?.responseType &&
                                con.response?.responseType ===
                                  "ProductListing" && (
                                  <SingleCategoryList
                                    index={index}
                                    responseTitle={con.response.responseTitle}
                                    text={con.response.responseText}
                                    branding={con.response.branding}
                                    products={con.response.products}
                                    conclusion={con.response.responseConclusion}
                                    delay={20}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick = {true}
                                    // onValueChange={handleValueChange}
                                    effects={false}
                                  />
                                )}
                              {con &&
                                con.response?.responseType ===
                                  "ProductDetail" && (
                                  <SpecificProductDetails
                                    index={index}
                                    responseTitle={con.response.responseTitle}
                                    text={con.response.responseText}
                                    branding={con.response.branding}
                                    product={con.response.products}
                                    conclusion={con.response.responseConclusion}
                                    delay={20}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick = {true}
                                    // onValueChange={handleValueChange}
                                    effects={false}
                                  />
                                )}
                              {/* Multi Outfit Template */}
                              {con &&
                                con.response?.responseType ===
                                  "ProductCollectionListing" && (
                                  <div>
                                    <MultiCatTemplate
                                      index={index}
                                      responseType={con.response.responseType}
                                      responseTitle={con.response.responseTitle}
                                      text={con.response.responseText}
                                      branding={con.response.branding}
                                      products={con.response.productCollections}
                                      conclusion={
                                        con.response.responseConclusion
                                      }
                                      delay={20}
                                      isAnswerComplete={props.isAnswerComplete}
                                      stopClick = {true}
                                      // onValueChange={handleValueChange}
                                      effects={false}
                                      
                                      // effects={
                                      //   conList.length - 1 === index
                                      //     ? true
                                      //     : false
                                      // }
                                    />
                                  </div>
                                )}
                              {/* <LikeAndDislike responseId={con.messageId} sessionChange={con.sessionChange}/> */}
                              {/* <ChatHistoryFeedBack
                                responseId={con.messageId}
                                sessionChange={con.sessionChange}
                              /> */}
                            </div>
                          )}
                        </div>
                      ))}
                    <ChatOperation data={location.state} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatHistory;
