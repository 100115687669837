function Configuration() {
    console.log('deployed')
    var configObject = {}
    if(process.env.REACT_APP_NODE_ENV === "dev") {
        configObject = {
            clientId: "ee804e3e-014d-442b-8ce5-0b3fecce0947",
            tenantId: "https://login.microsoftonline.com/f26ef77b-4ea6-4dd3-b7b3-0b4f109e26bd",
            redirectUri: "https://usdevvirtualstylistwebapp.azurewebsites.net/",
            microsoftapiUrl: "https://us-dev-virtualstylist-apim.azure-api.net/Chat",
            apiUrl: "https://usdevvirtualstylistwebapi.azurewebsites.net/",
            developers: ["abarajithan.pm@ralphlauren.com", "shubhangichainsing.thakur@ralphlauren.com", "Mickey.Abbatiello@RalphLauren.com",
            "shivakeerthi.karani@ralphlauren.com", "nicolas.smith@ralphlauren.com", "gaby.prieto@ralphlauren.com"]
        }
    } else if(process.env.REACT_APP_NODE_ENV === "production") {
        configObject = {
            clientId: "a3a2124d-872a-4b30-96e1-34887ae8a1a9",
            tenantId: "https://login.microsoftonline.com/f26ef77b-4ea6-4dd3-b7b3-0b4f109e26bd",
            redirectUri: "https://uspdvirtualstylistwebapp.azurewebsites.net/",
            microsoftapiUrl: "https://us-pd-virtualstylist-apim.azure-api.net/Chat",
            apiUrl: "https://uspdvirtualstylistwebapi.azurewebsites.net/",
            developers: ["abarajithan.pm@ralphlauren.com", "shubhangichainsing.thakur@ralphlauren.com", "Mickey.Abbatiello@RalphLauren.com",
            "shivakeerthi.karani@ralphlauren.com", "nicolas.smith@ralphlauren.com", "gaby.prieto@ralphlauren.com"]
        }
    } else {
        configObject = {
            clientId: "62c089f3-76d7-496d-903f-85bfd51f7053",
            tenantId: "https://login.microsoftonline.com/f26ef77b-4ea6-4dd3-b7b3-0b4f109e26bd",
            redirectUri: "https://usqavirtualstylistwebapp.azurewebsites.net/",
            microsoftapiUrl: "https://us-qa-virtualstylist-apim.azure-api.net/Chat",
            apiUrl: "https://usqavirtualstylistwebapi.azurewebsites.net/",
            developers: ["abarajithan.pm@ralphlauren.com", "shubhangichainsing.thakur@ralphlauren.com", "Mickey.Abbatiello@RalphLauren.com",
            "shivakeerthi.karani@ralphlauren.com", "nicolas.smith@ralphlauren.com", "gaby.prieto@ralphlauren.com"]
        }
    }
    //Please uncomment when you run locally
    // configObject = {
    //     clientId: "ee804e3e-014d-442b-8ce5-0b3fecce0947",
    //     tenantId: "https://login.microsoftonline.com/f26ef77b-4ea6-4dd3-b7b3-0b4f109e26bd",
    //     microsoftapiUrl: "https://us-dev-virtualstylist-apim.azure-api.net/Chat",
    //     redirectUri: "http://localhost:3000/",
    //      // apiUrl: "http://localhost:3001/",
    //     apiUrl: "https://usdevvirtualstylistwebapi.azurewebsites.net/",
    //     developers: ["abarajithan.pm@ralphlauren.com", "shubhangichainsing.thakur@ralphlauren.com", "shivakeerthi.karani@ralphlauren.com"]
    // }
    return configObject;
}

export const CONFIG_URL = Configuration()