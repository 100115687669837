import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import Restricted from '../CommonComponents/Restricted';
import { getPermittedUsers } from '../Services/ApiServices/userServices';

const PrivateRoute = ({ children }) => {
    const [allowedUsers, setAllowedUsers] = useState()
    
    useEffect(() => {
        const getUsers = async () => {
            await getPermittedUsers((res) => {
                setAllowedUsers(res.data.users);
            }, (err) => {
                console.log(err);
                localStorage.clear();

            })
        };
        if(accounts.length > 0){
            getUsers();
          } 
    }, [])
    
    const { accounts } = useMsal();
    const user = accounts[0]?.username;

    if (allowedUsers && allowedUsers.includes(user.toLowerCase())) {
        return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
    } else if(allowedUsers && !allowedUsers.includes(user.toLowerCase())) {
        return <Restricted/>;
    }
};

export default PrivateRoute;
