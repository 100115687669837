import React, { useEffect, useState, useCallback } from "react";
import MultiProductCard from "./MultiProductCard";
import Typewriter from "../../CommonComponents/TypeWriting";
import { useSelector, useDispatch } from 'react-redux';
import { loader, stopStreaming } from '../../Services/Redux/Reducers/commonSlice';
import { storeChat } from '../../Services/Redux/Reducers/chatSlice';
import { getTitle } from "../../Services/CommonServices/helper";

const MultiCatTemplate = (props) => {
  const products = props.products;
  const cardLength = products?.length;
  const text ="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.";
  const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
  const [isConclusionLoading, setIsConclusionLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isLastProductLoaded, setIsLastProductLoaded] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.isLoader);
  const streamingState = useSelector((state) => state.common.stopStreaming);

  useEffect(() => {
    if(props.isAnswerComplete && loading) {
      // console.log('log',(props.text!=null && isOverviewTextLoading == true),(products==undefined || products.length == 0 ) ,(((props.conclusion && props.conclusion!="" && isConclusionLoading==true))));
      
        if(((props.text!=null && isOverviewTextLoading == true) && ((props.conclusion && props.conclusion!="" &&isConclusionLoading == true)) && ( products && cardLength && isProductLoading == true))) {
          dispatch(storeChat(true));
          dispatch(loader(false));    
        }
    }
    if(!isOverviewTextLoading && !isProductLoading && !isConclusionLoading && streamingState) {
      dispatch(loader(true));
    }
  }, [props.conclusion, props.isAnswerComplete, products, props.text, isOverviewTextLoading, isConclusionLoading, loading,isProductLoading])

  const categoryListing = (products,productIndex) => {
    if (products) {
      console.log("products : " , products)
      let data = {
        text: "Show me details for the "+ getTitle(products) + " look.",               //as per Peter's comments
        query: "Can you show me details of outfit with ID "+products.id+"?",
        type: props.responseType,
        };
        if(!props.stopClick){
          props?.onValueChange(data);
        }
        dispatch(stopStreaming(true))
      }
  };
  //  add delay conditionally based on the size of the products, after the last product is loaded
  useEffect(() => {
    setTimeout(() => {
      if (
        products.length > 0 &&
        currentProduct === products.indexOf(products.slice(-1)[0])
      ) {
        setIsLastProductLoaded(true);
      }
    }, products.indexOf(products.slice(-1)[0]) * 1000);
  }, [currentProduct]);

  return (
    <div>
      <div class="row productdetails-div">
        <div className="col-12 col-lg-8">
          <div className="mcat-text">
          {/* {props.text} */}
          <Typewriter convoId={props.convoId} type='responseText' text={props.text} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsOverviewTextLoading} effects={props.effects}/>
          </div>

        </div>
      </div>

      {
            ( isOverviewTextLoading) && <div className="col-12">                   
          <div className="cards">        
            {products && products.map((productDetails, productIndex) => (
               <MultiProductCard
                // test="test123"
                  stopHover={props.stopClick}
                  categoryListing={() => {categoryListing(productDetails,productIndex);}}
                  key={productIndex}
                  product={productDetails}
                  productIndex={productIndex}
                  cardLength={cardLength}
                  convoId={props.convoId}
                  isAnswerComplete={props.isAnswerComplete}
                  effects={props.effects}
                  setProductIndex={setCurrentProduct}
                  delay={props.delay}
                />
            ))}
          </div>
              <Typewriter 
              text={''} 
              delay={props.delay} 
              isAnswerComplete={props.isAnswerComplete} 
              setLoading={setIsProductLoading} 
              effects={props.effects}
              convoId={"props.convoId"} 
              type='productDetail'  
              productIndex={0} 
              propertyKey={"name"}/>
     </div>
      }

{ (isProductLoading && isLastProductLoaded) && 
      <div className="row">
        <div className="col-lg-9 mb mcat-text">
        <Typewriter convoId={props.convoId} type='responseConclusion' text={props.conclusion} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsConclusionLoading} effects={props.effects}/>
        </div>
      </div>
 } 
    </div>
  );
};

export default MultiCatTemplate;
