import React, { useState , useEffect} from "react";
import TextWrapper from "./TextWrapper";

function MultiProductCard({ productIndex, product, cardLength, test, isAnswerComplete, convoId, delay, effects,categoryListing,setProductIndex,stopHover }) {
  // console.log("test", isAnswerComplete)
  const getClassName = (index) => {
    if (index === 0) {
      return "coat1";
    }
    if (index === 1) {
      return "shirt1";
    }
    if (index === 2) {
      return "pant1";
    }
    if (index === 3) {
      return "watch1";
    }
  };



  const slotHierarchy = {
    slot0 :  ["OUTERWEAR",  "JACKET",  "TAILORED CLOTHING",  "DRESSES",  "SWEATER",  "SHIRTS",  "TOPS",  "KNIT SHIRTS",  "SWIMWEAR",  "ONE PIECE",  "INTIMATE",  "SLEEPWEAR"],
    slot1 :   ["TAILORED CLOTHING",  "DRESSES",  "SWEATER",  "SHIRTS",  "TOPS",  "KNIT SHIRTS",  "BOTTOMS",  "SHORTS",  "SWIMWEAR",  "ONE PIECE",  "JEANS",  "PANTS",  "SKIRT",  "SKORTS",  "INTIMATE",  "SLEEPWEAR",  "BELTS",  "NECKWEAR",  "HEADWEAR", "EYEWEAR"],
    slot2 :   ["TAILORED CLOTHING",  "BOTTOMS",  "SHORTS",  "SWIMWEAR",  "ONE PIECE",  "JEANS",  "PANTS",  "SKIRT",  "SKORTS",  "INTIMATE",  "SLEEPWEAR",  "BELTS",  "NECKWEAR",  "HEADWEAR",  "EYEWEAR"],
    slot3 :   ["FOOTWEAR", "BOOTS",  "SHOE",  "SNEAKERS",  "SANDALS",  "SLIP-ONS",  "FLATS",  "DRESS SHOE",  "CASUAL SHOE",  "ESPADRILLES",  "CLOG",  "PUMPS",   "BELTS",  "NECKWEAR",  "HEADWEAR",  "EYEWEAR"]
  }
 

  let renderedCats = []

  const fetchImage = (slot, data, hierarchy) => {
    let finaldata;

    const categoryObjectMap = new Map()

    // console.log("categoryObjectMap", categoryObjectMap)

    data.forEach(element => {
      if(!categoryObjectMap.has(element.productCategory)) {
        categoryObjectMap.set(element.productCategory,element)
      }
    });

    if (slot === 0) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category) && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)
          break;
        } 
      }
    } else if (slot === 1) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category)  && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)

          break;
        } 
      }
    } else if (slot === 2) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category)  && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)

          break;
        } 
      }
    } else if (slot === 3) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category)  && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)

          break;
        } 
      }
    } else {
      finaldata = null
    }

// console.log("renderedCats", renderedCats)
  return <>{imageTemplate(finaldata, slot)}</>

  }

  const imageTemplate = (productData, prodIndex) => {
    return (
      <>
        {productData != null && productData.media.map((imageUrl, imgIndex) => (
          <>
            {imageUrl.type === "PrimaryImage" && (
              <img
                src={
                  imageUrl.url.split("?fmt=webp-alpha")[0] +
                  "?$rl_5x7_pdp$&fmt=webp-alpha"
                }
                alt="img"
                className={`img-fluid  ${getClassName(prodIndex)}`}
              />
            )}
          </>
        ))}
        {
          productData === null && <></>
        }
      </>
    );
  };

  console.log("product :",product)
  // const headText = product.name;
  //const words = headText.split(" ");

  const words = product.name;
  const maxChars = 13;
  
  useEffect(() => {
    setProductIndex(productIndex);
  }, []);

  return (
   
    <div style={{ animationDelay: `${productIndex * 1}s` }} className={`box ${stopHover ? "noHover" : "effectHover"} col-7 col-md-4 fade-in  ${productIndex === 0 && 'mcat-box-ms'} ${productIndex === (cardLength-1) && 'mcat-box-me'}`} onClick={categoryListing}>
          <div>
            <span className="textLook">
              {"LOOK " + `${productIndex + 1}` + " OF " + cardLength}
            </span>
          </div>

          <div className="content-title_container">
            <span className="content-title">        
            <TextWrapper text={words} />
            </span>
          </div>
          
              
              {fetchImage(0,product.products,slotHierarchy.slot0)}
              
              
              {fetchImage(1,product.products,slotHierarchy.slot1)}
              
              
              {fetchImage(2,product.products,slotHierarchy.slot2)}
              
              
              {fetchImage(3,product.products,slotHierarchy.slot3)}
              
           

            {/* <div>
              <>
              {fetchImage(0,product.products,slotHierarchy.slot0)}
              </>
              <>
              {fetchImage(1,product.products,slotHierarchy.slot1)}
              </>
              <>
              {fetchImage(2,product.products,slotHierarchy.slot2)}
              </>
              <>
              {fetchImage(3,product.products,slotHierarchy.slot3)}
              </>
            </div> */}

        <div className="bottomTextContainer">
          <span className="viewText">+ View Look</span>
        </div>
    </div>
 
  );
}

export default MultiProductCard;
