import React, { useEffect, useState } from "react";

function FaqBeta() {

    return(
        <div className="usersection_faqbeta px-2 py-2">
        <div className="faq-beta">
               <div className="faq-header">Ask Ralph FAQ's : Beta Version</div>
         </div>
           <div className="faq-beta">
               <div className="faq-title">WHAT IS ASK RAPLH?</div>
               <div className="faq-content">Developed by Ralph Lauren, Ask Ralph is an AI-powered styling 
                assistant that offers personalized fashion guidance and a streamlined shopping experience.
                It is our newest venture in conversational commerce, using machine learning and natural 
                language processing to understand and assist customers with their style choices.
               
               </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">WHO IS THIS AVAILABLE TO?</div>
                   <div className="faq-content">Currently, Ask Ralph is in beta and available exclusively to
                     a select number of employees through the Ralph Lauren App in the United States as part of an 
                     internal testing phase.Feedback and performance during this period will inform future 
                     enhancements to the program.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">ARE ALL PRODUCTS AVAILABLE WITHIN THE ASK RALPH STYLING ASSISTANT?</div>
                   <div className="faq-content">Ask Ralph is currently in beta and will only recommend products 
                    from our Men’s and Women’s Polo Ralph Lauren Brand. 
               </div>
            </div>
           <div className="faq-beta">
               <div className="faq-title">WHAT CAN THE ASK RALPH STYLIST DO?</div>
                   <div className="faq-content">Ask Ralph is your personal AI stylist made to assist you in your shopping journey. 
                    Whether you're looking for outfit inspiration for a glamorous party, or searching for the perfect gift,
                     Ask Ralph is here to help. Imagine asking, "What should I wear for a wedding reception in the Hampton’s next March?" or 
                     "Show me different Polo Bear themed tops." or “What gift ideas do you have for a friend?” Ask Ralph is designed to answer 
                     all of these questions and more, making your shopping experience seamless and enjoyable. 
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">HOW DOES ASK RALPH WORK AND COME UP WITH ITS ANSWERS?</div>
                   <div className="faq-content">Ask Ralph works by using artificial intelligence to 
                    interact with you in a conversational manner. When you ask a question or make a request, 
                    the AI analyzes your input and draws from its extensive knowledge of Ralph Lauren's products, 
                    brand knowledge, style guidelines, and information across the web to offer recommendations.
                    You can refine your requests by providing more details and Ask Ralph will adapt its suggestions 
                    accordingly. Please note that Ask Ralph is designed to act like a styling assistant, 
                    so refrain from asking financial, medical, legal, or any other derogatory and inappropriate information. 
                    Please avoid disclosing any personal or sensitive information, too.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">HOW ACCURATE ARE THE AI STYLIST'S SUGGESTION?</div>
                   <div className="faq-content">The Ask Ralph stylist is currently in beta.
                     As we continue to test and evolve the tool there will be opportunities where the 
                    responses are not 100% accurate or are aligned with our Ralphlauren.com or in-store experiences. 
                    If this is the case, please use the feedback mechanisms to report these instances.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">HOW DO I FIND THE RIGHT OUTFIT FOR SPECIFIC OCCASION? (eg:wedding, job interview)</div>
                   <div className="faq-content">To find the right outfit for a specific occasion, you can simply tell 
                    Ask Ralph about the event you are attending, your style preferences, sizes, and any other requirements. 
                    Ask Ralph will present you with a curated selection of Ralph Lauren attire that fits your needs,
                    offering options for different looks and suggesting complete outfits to ensure you are dressed for the occasion.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">WHAT SHOULD I DO IF THE VIRTUAL STYLIST DOES NOT UNDERSTAND MY REQUEST?</div>
                   <div className="faq-content">If Ask Ralph does not understand your requests, try to provide more specific information, 
                    or use simpler, clearer language. Ensure that you are being direct with your questions or requests. 
                    The more detailed your question the better the response will be.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">IS THERE A LIMIT TO HOW MANY TIMES I CAN USE THE ASK RALPH STYLIST?</div>
                   <div className="faq-content">There is no limit to how many times you can use Ask Ralph. There is a timeout period in which due to
                     inactivity your chat conversation will be saved and stored in chat history.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">HOW CAN I ACCESS AND CLEAR MY CHAT HISTORY?</div>
                   <div className="faq-content">Chat history will be stored and accessible through the main home page of Ask Ralph.
                    Upon clicking on one of the chats you will be able to review or delete previous chat history.
                    Currently there is no ability to re-engage with a chat and input new questions or responses. 
                    Clearing chat history removes questions and responses from that specific chat. 
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">CAN I SAVE MY FAVORITE OUTFIT OR RECOMMENDATION?</div>
                   <div className="faq-content">There is no direct feature to save outfits and store them. However,
                    all outfits and conversations will be stored in  your chat history for you to reference previous 
                    conversations with outfit and product recommendations.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">CAN THE AI STYLIST CONSIDER MY EXISTING WARDROBE?</div>
                   <div className="faq-content">Currently the beta version of Ask Ralph does not incorporate 
                    the items within your Ralph Lauren Wardrobe.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">IS THE VIRTUAL AVAILABLE IN MULTIPLE LANGUAGES?</div>
                   <div className="faq-content">Currently, Ask Ralph is only available within the RL App in the
                     United States. The English version of Ask Ralph is currently in beta.
                     Multi-language support may be considered in future expansions.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">WHY DID I GET A RESPONSE THAT DID NOT DIRECTLY ANSWER MY QUESTION?</div>
                   <div className="faq-content">There are multiple reasons why Ask Ralph may not answer a question. 
                     Ask Ralph is mainly a styling and brand knowledge tool. Certain responses may not be available right now, 
                     but we are actively working to expand and answer more questions. Ask Ralph may choose not to respond
                     to certain questions due to the inappropriate nature of them or if it is outside our brand guidelines.
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">WHAT SHOULD I DO IF I'm NOT SATISFIED WITH THE RECOMMENDATION?</div>
                   <div className="faq-content">If not satisfied with a recommendation, please provide feedback through 
                    our thumbs up / thumbs down feedback feature. Then, treat this  experience as a conversation and provide 
                    more context, re-word the question, or ask follow up questions. 
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">HOW CAN I PROVIDE FEEDBACK ON THE AI STYLIST's RECOMMENDATION?</div>
                   <div className="faq-content">To provide feedback on your recommendation, 
                     there is a thumbs up/thumbs down feature that lets you determine the recommendation's quality 
                     as it relates to your input. This will allow us to continue to improve our tool over time to 
                     enhance your experience with Ask Ralph.There will also be opportunities to provide feedback
                     on your overall Ask Ralph experience.
                     
                   </div>
           </div>

           <div className="faq-beta">
               <div className="faq-title">PRIVACY AND SECURITY</div>
                   
           </div>

           <div className="faq-beta">
               <div className="faq-title">WHAT PERSONAL INFORMATION DOES THE VIRTUAL STYLIST COLLECT?</div>
                   <div className="faq-content">Ask Ralph will store information related to the chat history of the conversation, 
                    as well as any feedback you provide through the use of the assistant. Your email address is also stored for 
                    authentication purposes. The feedback provided regarding recommendations and the Ask Ralph tool will be collected and 
                    analyzed to improve the quality of the assistant. At present, the tool does not collect information related to your
                    customer profile with us, and only relies on chat conversation to provide recommendations.  
                     
                   </div>
           </div>

           <div className="faq-beta">
               <div className="faq-title">IS MY PERSONAL INFORMATION SECURE WHEN USING THE TOOL?</div>
                   <div className="faq-content">Yes, personal information remains in the Ralph Lauren cloud landscape,
                     where it is encrypted  and stored. Your personal information is not shared with third parties as a result of using the tool. 
                    Your personal information is not transferred outside of North America as part of the tool’s use.
                     
                   </div>
           </div>
           <div className="faq-beta">
               <div className="faq-title">HOW CAN I LEARN ABOUT HOW MY PERSONAL DATA IS USED AND PROCESSED?</div>
                   <div className="faq-content">If you want to learn more about how your personal data can be used by Ralph Lauren, 
                    please read our privacy policy  <a href="https://www.ralphlauren.com/customerservice?cid=cs-privacy-notice" target="_blank" rel="noopener noreferrer"> here,</a>or contact us at
                    <a href="mailto:privacy@ralphlauren.com" target="_blank" rel="noopener noreferrer"> privacy@ralphlauren.com.</a> 
                     
                   </div>
           </div>


        </div>
        
    );
}

export  default FaqBeta;