import React, { useState,useEffect,useRef,useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import PromptsCarousel from "./PromptCarousel/PromptsCarousel";
import ArchiveCarousel from "./ArchiveCarousel/ArchiveCarousel";
import { uniqueGroupSessionHistory } from "../Services/ApiServices/chatServices";
import { useDispatch } from "react-redux";
import { apiError, conversationList } from "../Services/Redux/Reducers/chatSlice";
import { loader, stopStreaming, userPrompt } from "../Services/Redux/Reducers/commonSlice";
 
function Useronboard() {
  // const [isOpen, setIsOpen] = useState(true);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
 
  const buttonRef =useRef(null);
  const inputRef =useRef(null);
  const intervalRef = useRef(null)
 
  const [isPlaceHolderVisible, setIsPlaceHolderVisible] =useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] =useState(true);
  const [animateState,setAnimateState]=useState(false);
  const [onboardLoading, setOnboardLoading] = useState(false);
  const [promptFade, setPromptFade] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselData,setCarouselData] = useState([]);
  const[isLoading,setIsLoading] = useState(true)
  const [modalDeleteState,setModalDeleteState]  = useState(false)
  // const [currentMsgIndex, setCurrentMsgIndex] = useState(0);
  const [currentMsgIndex, setCurrentMsgIndex] = useState(
    (parseInt(localStorage.getItem('questionIndex')) || 0)
  );
  const [isFocused, setIsFocused] = useState(false);
  const [onPromptOpen, setOnPromptOpen] = useState();
  const [onPromptClose, setOnPromptClose] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const [userName, setUserName] = useState("");
  const isMounted = useRef(true);
  const prevIndexRef = useRef(0);
  const { accounts } = useMsal();
  const dispatch = useDispatch();

 
  const inputMsg = ["What should I wear to the wedding?","What ties should I wear with white shirt?","What are Ralph's most iconic Polo pieces","I am looking for a suit jacket for the wedding"]
  
  const slides = [{title1 : 'FOR AN OCCASION' , title2:'What would you recommend I wear for a summer', title2_1: 'wedding in Milan?',ques:'What would you recommend I wear for a summer wedding in Milan?'},
  {title1 : 'HOW TO WEAR' , title2:'Show me how to tie the perfect tie knot.',ques:'Show me how to tie the perfect tie knot.'},
  {title1 : 'GIFTING ADVICE' , title2:'What should I gift my mother for Mother’s Day?',ques:'What should I gift my mother for Mother’s Day?'}]


  // for slider data function
  const storeMessage = async (accounts) => {
    uniqueGroupSessionHistory(accounts[0].username,(response) => { // Chat History storing the messages      
    setCarouselData(response.data);
    setIsLoading(false)
    }, (err) => {
      dispatch(apiError(err.message));
      dispatch(loader(false));
    });
  }

  useEffect(() => {
    storeMessage(accounts)
  },[modalDeleteState])


  useEffect(()=> {
    if(accounts.length !== 0) {
        if(accounts[0].name.includes(',')){
            let firstname = accounts[0].name.split(',')[1];
            firstname = firstname.split(' ')[1];
            setUserName(firstname);
        } else {
            let firstname = accounts[0].name;
            setUserName(firstname);
        }
        // storeMessage(accounts);
    }
}, [accounts])

  const handleFocus=()=>{  
    setIsPlaceHolderVisible(false);  //true
    //clearInterval(intervalRef.current);
    setIsFocused(true);   
    setIsSendButtonDisabled(false); 
  }
 
  const handleInputBlur =()=>{
    setIsFocused(false);
    //setCurrentMsgIndex(0);
    //clearInterval(intervalRef.current);
    setIsSendButtonDisabled(true);
  }

//   useEffect(()=>{
//     isMounted.current=true;
//      return () => isMounted.current=false;
//     },[]);

//   const interval = 6000;
//   useEffect(()=>{
//     if(isMounted.current && isAnimating && !showCarousel){
//     const intervalId = setInterval(()=>{
//       setCurrentMsgIndex((prevIndex)=>(prevIndex < inputMsg.length -1 ? prevIndex +1 : 0))
//     },interval);
 
   
//     return () => clearInterval(intervalId);
//   }
// },[isMounted,isAnimating,showCarousel,inputMsg]);
 
 useEffect(()=>{
        prevIndexRef.current = currentMsgIndex;
 },[currentMsgIndex])

useEffect(() => {
  isMounted.current = true;
  if (isAnimating && !showCarousel) {
    startMsgAnimation();
  }
  // } else {
  //   clearInterval(intervalRef.current);
  // }
  return () => {
    clearInterval(intervalRef.current);
    isMounted.current = false;
  };
}, [isAnimating, showCarousel, inputMsg.length]);

const startMsgAnimation = () => {
  clearInterval(intervalRef.current);
  intervalRef.current = setInterval(() => {
    if(isMounted.current) {
      setCurrentMsgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % inputMsg.length;
        localStorage.setItem('questionIndex', newIndex);
        return newIndex;
      });
    }
  }, 6000);
};

  const closeCarousel = () => {
    setIsSendButtonDisabled(true);
    setPromptFade(false);
    setTimeout(() => {
      setShowCarousel(false);
    }, 830);
  };
 
  const onHandleInputChange =(e)=>{
    const inputValue = e.target.value
    setInput(inputValue)
    setIsSendButtonDisabled(inputValue.trim()==='');
  }
   
  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsPlaceHolderVisible(input === '' || !input);
    }
    else if(buttonRef.current && !buttonRef.current.contains(event.target)){
    setIsPlaceHolderVisible(input === '' || !input)
    }
  };
 
  const onHandAnimation=()=>{
    dispatch(stopStreaming(true))
    dispatch(conversationList([]));
    setAnimateState(true);
    setOnboardLoading(true);
    dispatch(loader(false))
    dispatch(userPrompt(input));
     setTimeout(()=>{
      navigate("/chat");
    },2000)
  }

  useEffect(() => {
    const handleClickOutside=(event)=>{
     if(inputRef.current && !inputRef.current.contains(event.target)){
       setIsPlaceHolderVisible(true);
       startMsgAnimation();
      //  setIsSendButtonDisabled(true);
      //  if(!input){
      //   setIsSendButtonDisabled(false); //change
       
      //  }
     }
    } ;
      document.addEventListener("mousedown", handleClickOutside);
     return () => {
      document.removeEventListener("mousedown", handleClickOutside);
     };
   }, []);


   const handleCaroselAnimate =(ques)=>{
    setInput(ques);                          
    // closeCarousel();
    setIsPlaceHolderVisible(false);
    setIsSendButtonDisabled(false);
    // setOnboardLoading(false)
    dispatch(loader(false))
    // setOnPromptOpen(false);
    // setOnPromptClose(true);
    // onHandAnimation();
    const element= document.getElementById("para");
    if(element){
      element.focus();
    }
}

useEffect(() => {
  setTimeout(() => {
    if(document.getElementById("next")) {
      document.getElementById("next").click()
    }
  }, 3000);
 }, [showCarousel])



  return (  
 
    <div className={`container container-sm container-xs container-md container-xl container-xxl container-fluid useronboard  mx-auto py-5  img-fluid ${animateState ? 'backgroundOut' : '' }` }>
        <div class="row align-items-center justify-content-center">
        <div className="logo">
          <img
             src="./static/assets/askralphlogo.svg"
             class="img-fluid logo-img"
             width="400"
            height="100.998px"
            alt="no img"
             />
          </div>
     
            <div className={`chatbox box-msg d-flex justify-content-center align-items-center animateSearch ${animateState ? 'animateSearchOut' : ''}`}>
             <input value={input} ref={inputRef} className="form-control msg" type="text" id="para"                   
                    // autoFocus
                    onFocus={handleFocus}
                    onBlur={handleInputBlur}
                    onChange={(e) => onHandleInputChange(e)}
                    onKeyDown={(e) => {
                       if (e.code === "Enter") {
                         e.preventDefault()
                         onHandAnimation() 
                         dispatch(stopStreaming(true))                        
                       }
                    }}
              />
             
            {isPlaceHolderVisible && !input &&  
            <div className="d-block align-middle">
           
            <span onClick={() => {document.getElementById("para").focus()}} className={`leJeuneDeck col-12 message-fade ${!showCarousel ? 'animation-msg':''}`}>{inputMsg[currentMsgIndex]}</span>
              </div>
            }
               
          {!onboardLoading ? (  
            <button
              onClick={() => {onHandAnimation()}}
              className={`arrow-dist send-arrow-position ${
                isSendButtonDisabled ? "disabled" : "enabled"
              } `}
              disabled={!input || (isFocused || input.trim() === '') && isSendButtonDisabled}
            >
              <img
                src={
                  isSendButtonDisabled
                    ? "/static/assets/arrowlong.svg"
                    : "/static/assets/bluearrow.svg"
                }
                height="36px"
                width="36px"
                alt="arrow-img"
                className="arrow_long"
              />
            </button>
          ) : (
            <div className="onboard-loader" role="status"></div>
          )}
               
          </div>
          </div>
          <br/>
 
          <div className="row d-grid">
                             
             <div className="container-fluid userOnboardContainer col-12 col-md-10 col-xl-10 px-0 px-xl-4">
            {!isLoading && carouselData && carouselData.length === 0 && (
              <PromptsCarousel
                handleCaroselAnimate={(q) => handleCaroselAnimate(q)}
              />
            )}
            {!isLoading && carouselData && carouselData.length > 0 && (
              <ArchiveCarousel
                handleCaroselAnimate={(q) => handleCaroselAnimate(q)}
                data={carouselData}
                setModalDeleteState={setModalDeleteState}
              />
            )}
             </div>
         </div>
        {/* </div> */}
    </div>
  );
}
 
export default Useronboard;    