import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import img1 from "./OnboardingImages/1-Welcome@2x.png";
import img2 from "./OnboardingImages/2-Outfits@2x.png";
import img3 from "./OnboardingImages/3-Discover@2x.png";
import img4 from "./OnboardingImages/4-Gifting@2x.png";
import img5 from "./OnboardingImages/5-Feedback@2x.png";

const OnboardCarousel = ({ handleGetStartedClick }) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const slideRef = useRef();
  const slides = [
    {
      image: img1,
      headline: "Welcome To Ask Ralph",
      subtext:
        "You have been selected to trial Ask Ralph — your AI personal stylist. Currently in beta, Ask Ralph is here to assist your shopping journey.",
    },
    {
      image: img2,
      headline: "Explore Endless Outfits",
      subtext:
        "Tailored to your needs, Ask Ralph helps you find looks for any occasion and suggests outfits you'll love.",
    },
    {
      image: img3,
      headline: "Discover Iconic Pieces ",
      subtext:
        "Ask Ralph effortlessly pairs you with products through natural conversation, helping you perfect your style.",
    },
    {
      image: img4,
      headline: "Perfect Gifting Made Easy",
      subtext:
        "Ask Ralph can suggest the perfect present from Ralph Lauren for everyone on your list — even those hard-to-shop-for loved ones.",
    },
    {
      image: img5,
      headline: "Create Along With Us",
      subtext:
        "Help us improve your Ask Ralph experience by providing your feedback.",
    },
  ];

  useEffect(() => {
    // Initialize progress based on the active slide
    const activeIndex =
      document.querySelector(".carousel-item.active")?.dataset.index || 0;
    setProgress(((parseInt(activeIndex) + 1) / slides.length) * 100);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = slideRef.current.clientWidth;
      slideRef.current.style.transform = `translateX(${-index * width}px)`;
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [index]);

  const showNextSlide = () => {
    setIndex((index + 1) % slides.length);
    setProgress((prev) => Math.min(100, prev + 100 / slides.length));
  };
  const handleRedirect = () => {
    handleGetStartedClick();
    navigate("/");
  };

  const handleSlide = (event) => {
    const activeIndex = event.to;
    setProgress(((activeIndex + 1) / slides.length) * 100);
  };

  return (
    <div className="px-0">
      <div className="carousel" onSlide={(e) => handleSlide(e)}>
        <div className="slides col-10" ref={slideRef}>
          {slides.map((slide, i) => (
            <div key={i} className={`slide ${index === i ? "active" : ""}`}>
              <img src={slide.image} height="359px" width="293px" />
              <div
                className="col-12 px-4 d-grid align-items-start"
                style={{ position: "absolute", bottom: "10%" }}
              >
                <dv className="head_line">{slide.headline}</dv>
                <div className="sub_text mt-3">{slide.subtext}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="footer px-5">
        <div class="px-3">
          <div className="progress mt-4">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress}%` }}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div className="d-flex mt-4 px-2">
          {progress === 100 ? (
            <button className="nextBtn" onClick={handleRedirect}>
              Get started <span>&#8594;</span>
            </button>
          ) : (
            <button className="nextBtn" onClick={showNextSlide}>
              Next <span>&#8594;</span>
            </button>
          )}
        </div>
        <div className="text-center mt-3">
          <button className="skip-text" onClick={handleRedirect}>
            Skip Tutorial
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardCarousel;
