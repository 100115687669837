import React from 'react'

function ErrorComponent({error}) {
    
    const getErrorMessage = ()=>{
        if(error.errorCode === "interaction_required"){
            return "User interaction is required to complete the login.";

        }else if(error.errorCode === "AADB2C90118"){
            return "User needs to reset their password.";
        } else if(error.errorCode === "login_progress"){
            return "Another login is already in progress.";
        }else{
            return error?.errorMessage || "An unknown error occurred during login"
        }
    };


    return (
        <div>
            <h2>An error occurred</h2>
            <p>{getErrorMessage()}</p>
        </div>
    )
 }
 export default ErrorComponent;