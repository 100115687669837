import { Outlet } from "react-router-dom";
import Header from "../CommonComponents/Header";
import { useEffect, useState } from "react";

function MainLayout() {
  // code for iphone device 
  const [isIos, SetIos] = useState(true);

  useEffect(()=> {
    let userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ((macosPlatforms.test(userAgent)) || (windowsPlatforms.test(userAgent)) || ((/android/.test(userAgent)) && !safari )){
      SetIos(false);
    }
  },[]);

  return (
  <div className="mainlayout">
      <Header />
      <div className={`${!isIos && 'mainroute_height'} mainroute`}>
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
