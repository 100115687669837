import React, { useEffect, useState } from 'react'
import Typewriter from '../../CommonComponents/TypeWriting';
import ProductTypeWriter from '../../CommonComponents/ProductTypeWriter';
import Divider from '../../CommonComponents/Divider';
import { useSelector, useDispatch } from 'react-redux';
import { loader } from '../../Services/Redux/Reducers/commonSlice';
import { storeChat } from '../../Services/Redux/Reducers/chatSlice';

export default function SingleCategoryList(props) {
    const [isCategory, setIsCategory] = useState();
    const [brandingImg, setBrandingImg] = useState();
    const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [isConclusionLoading, setIsConclusionLoading] = useState(false);
    const dispatch = useDispatch();
    const conList = useSelector((state) => state.chat.conversationList);
    const loading = useSelector((state) => state.common.isLoader)
    const streamingState = useSelector((state) => state.common.stopStreaming)
    
    useEffect(() => {
        if(props.products && props.products !== null && props.products !== undefined && props.products.length > 0) {
            let category = props.products[0].type;
            if (props.products.every((i) => i.type === category)) {
                setIsCategory(true);
            }
        }
    }, [props.products])

    useEffect(() => {
        if(props.isAnswerComplete && loading) {
            if(
                props.text!=null && isOverviewTextLoading == true &&
                (props.products==undefined || props.products.length == 0 || (props.products && isProductLoading == true)) &&
                (props.conclusion==undefined || props.conclusion=="" || (props.conclusion && props.conclusion!="" && isConclusionLoading==true))
            ) {
                dispatch(storeChat(true));
                dispatch(loader(false));
            } 
            getBrandingImage();
        } else {
            getBrandingImage();
        }
        if(!isOverviewTextLoading && !isProductLoading && !isConclusionLoading && streamingState) {
            dispatch(loader(true));
        }
    }, [props.conclusion, props.isAnswerComplete, props.products, props.text, isOverviewTextLoading, isProductLoading, isConclusionLoading, loading])

    const getBrandingImage = () => {
        if(props.outfitUrl === '' || props.outfitUrl === null || props.outfitUrl === undefined)
        {

        if(brandingImg === "" || brandingImg === undefined) {
            if(props.branding && props.branding.length > 0) {
                if((props.branding[0] && props.branding[0].media && props.branding[0].media.length > 0 && props.branding[0].media[0]) && (props.branding[0].media[0].url!=="")) {
                setBrandingImg(props.branding[0].media[0].url)
                }  
            } else if(((props.products &&
                props.products !== null && props.products.length > 0 &&
                props.products[0] &&
                props.products[0].media &&
                props.products[0].media !== null &&
                props.products[0].media !== undefined))) {
                let selectedPrimaryImage = props.products[0].media.filter(item => item.url.match(/alternate10\?fmt=webp-alpha$/))
                setBrandingImg((selectedPrimaryImage&& selectedPrimaryImage.length>0) && selectedPrimaryImage[0].url.split("?fmt=webp-alpha")[0]+"?$rl_4x5_pdp$&fmt=webp-alpha")
            } 
            // else if(((props.products &&
            //   props.products !== null &&
            //   props.products[0] &&
            //   props.products[0].media &&
            //   props.products[0].media !== null &&
            //   props.products[0].media[0]) && (props.products[0].media[0].url!=="")) && props.products[0].media[0].type === "PrimaryImage") {
            //     setBrandingImg(props.products[0].media[0].url)
            // }
        }
    }else{
        setBrandingImg( props.outfitUrl.split("?fmt=webp-alpha")[0]+"?$rl_4x5_pdp$&fmt=webp-alpha") 
    }
    }

    const handleValueChange = (childValue) => {
        // props.onValueChange(childValue)
        if(!props.stopClick){
            props?.onValueChange(childValue);
          }
    };

    return (
        <div className='single_category_list mb'>
            <div className={`main-section sc-main ${brandingImg === "" && "product_header"}`}>
                <div className="row">
                    {brandingImg && 
                        <div className="col-5 col-md-5 col-lg-4 d-flex justify-content-start align-items-center i-pad me-lg-5 me-xl-4 ps-2 ms-1 ps-lg-2 ps-xl-4 me-xxl-0 pe-xxl-0 editorial-img">    
                            <img
                                src={brandingImg ? brandingImg : "./static/assets/no-image.png"}
                                className="img-fluid"
                                width="320"
                                height="400"
                                alt="no img"
                            />  
                        </div>
                    }
                    <div
                        className={`${
                        brandingImg
                            ? "col-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center mt-3 mt-sm-3 mt-md-4 ms-1 ms-lg-0 ps-0"
                            : "col-12 col-md-12 col-lg-12 d-flex flex-column justify-content-center ps-0"
                        }`}
                    >
                        <span className="para-main text-Uppercase leJeuneDeck">  
                            <div className={`${brandingImg ? "col-lg-12 ms-2 intro_text": "col-lg-9 ms-2 ps-1"}`}>
                            <Typewriter convoId={props.convoId}  type="responseText" text={props.text} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsOverviewTextLoading} effects={props.effects}
                            isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}/>
                            </div>
                        </span>
                    </div>
                    <div>
                        { ((props.index !== conList.length-1 && props.products && props.products.length > 0) || ((props.text === "" || isOverviewTextLoading) && props.products && props.products.length > 0)) && 
                            <div className={"productscard"+props.index}>
                            {
                                props.text && 
                                <div className="col-lg-12 product-list-top-divider">
                                <Divider borderTop="border-2" borderBottom="border-1" />
                                </div>
                            }
                            <ProductTypeWriter convoId={props.convoId} index={props.index} products={props.products} delay={props.delay} isAnswerComplete={props.isAnswerComplete} conclusion={props.conclusion} isCategory={isCategory} setLoading={setIsProductLoading} onValueChange={handleValueChange} isStreaming={props.isStreaming} effects={props.effects} 
                            isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}/>
                            </div>
                        }
                    </div>
                    <div>
                        { ((props.index !== conList.length-1 && props.conclusion && props.conclusion != '') || (((props.products === undefined || (props.products && props.products.length === 0) && (props.isAnswerComplete || props.isAnswerComplete === undefined)) || isProductLoading) && isOverviewTextLoading && props.conclusion && props.conclusion != '')) && (
                            <div>
                                <div className="col-lg-12 product-list-btm-divider">
                                <Divider borderTop="border-1" borderBottom="border-2"/>
                                </div>
                                <div className="col-lg-9 para-main leJeuneDeck">
                                <Typewriter  type="responseConclusion" convoId={props.convoId} text={props.conclusion??""} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsConclusionLoading} effects={props.effects}
                                  isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
