import { msalInstance } from '../Services/Authentication/auth_config';
import { useNavigate } from "react-router-dom";

function Restricted() {
    const navigate = useNavigate();
    
    const handleLogout = () => {
        msalInstance.logoutRedirect();
        localStorage.clear();
        navigate("/");
    };

    return (
        <div className=" d-flex justify-content-center align-items-center pagenotfound">
            <h1>
                Access Restricted, Click here to <button className='btn btn-primary' onClick={()=>handleLogout()}>logout</button>.
            </h1>
        </div>
    );
}

export default Restricted;