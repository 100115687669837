import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MyCarousel({ handleCaroselAnimate }) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 0,
          // infinite: true,
        },
      },
    ],
  };
  const cards = [
    {
      id: 1,
      title: "WELCOME",
      content: "We’re here to take you on a shopping journey",
    },
    {
      id: 2,
      title: "Outfit For An Occasion",
      content:
        "What outfits should I wear for a [season] wedding in [location]? ",
    },
    {
      id: 3,
      title: "Product Discovery ",
      content: "What Polo [category] do you have in [color] in a [size]? ",
    },
    {
      id: 4,
      title: "Gifting",
      content: "What gift should I get my [significant]?",
    },
    {
      id: 5,
      title: "Brand History",
      content:
        "Tell me more about the origin of the [Tweed jacket, Polo Bear, Ralph Lauren]",
    },
  ];

  return (
    <div className="carousel-container slider-container container-fluid px-0 col-12 col-md-10 col-xl-9">
      <Slider {...settings}>
        {cards.map((card, i) => (
          <div>
            <div className="headerContainer">
              <span className="headerContainer_title">{card?.title}</span>
              {i > 0 && (
                <button
                  className="headerContainer_tryBtn btn--outline"
                  onClick={() => handleCaroselAnimate(card?.content)}
                >
                  Try it
                </button>
              )}
            </div>
            <div className="cardContent">
              <p className="cardContent-item">{card?.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MyCarousel;
