import React, { useState } from "react";

function MultiProductCard({ productIndex, product, cardLength, test, isAnswerComplete, convoId, delay, effects }) {
  // console.log("test", isAnswerComplete)
  const getClassName = (index) => {
    if (index === 0) {
      return "coat1";
    }
    if (index === 1) {
      return "shirt1";
    }
    if (index === 2) {
      return "pant1";
    }
    if (index === 3) {
      return "watch1";
    }
  };



  const slotHierarchy = {
    slot0 :  ["OUTERWEAR",  "JACKET",  "TAILORED CLOTHING",  "DRESSES",  "SWEATER",  "SHIRTS",  "TOPS",  "KNIT SHIRTS",  "SWIMWEAR",  "ONE PIECE",  "INTIMATE",  "SLEEPWEAR"],
    slot1 :   ["TAILORED CLOTHING",  "DRESSES",  "SWEATER",  "SHIRTS",  "TOPS",  "KNIT SHIRTS",  "BOTTOMS",  "SHORTS",  "SWIMWEAR",  "ONE PIECE",  "JEANS",  "PANTS",  "SKIRT",  "SKORTS",  "INTIMATE",  "SLEEPWEAR",  "BELTS",  "NECKWEAR",  "HEADWEAR",  "BAGS",  "EYEWEAR",  "FINE JEWELRY",  "FASHION JEWELRY",  "SCARF",  "GLOVES",  "SMALL LEATHERGOODS",  "APPAREL ACCESSORIES",  "SMALL ACCESSORIES"],
    slot2 :   ["TAILORED CLOTHING",  "BOTTOMS",  "SHORTS",  "SWIMWEAR",  "ONE PIECE",  "JEANS",  "PANTS",  "SKIRT",  "SKORTS",  "INTIMATE",  "SLEEPWEAR",  "BELTS",  "NECKWEAR",  "HEADWEAR",  "HOSIERY",  "BAGS",  "EYEWEAR",  "FINE JEWELRY",  "FASHION JEWELRY",  "SCARF",  "GLOVES",  "SMALL LEATHERGOODS",  "APPAREL ACCESSORIES",  "SMALL ACCESSORIES"],
    slot3 :   ["BELTS",  "NECKWEAR",  "HEADWEAR",  "HOSIERY",  "BAGS",  "EYEWEAR",  "FINE JEWELRY",  "FASHION JEWELRY",  "SCARF",  "GLOVES",  "SMALL LEATHERGOODS",  "APPAREL ACCESSORIES",  "SMALL ACCESSORIES", "FOOTWEAR", "BOOTS",  "SHOE",  "SNEAKERS",  "SANDALS",  "SLIP-ONS",  "FLATS",  "DRESS SHOE",  "CASUAL SHOE",  "ESPADRILLES",  "CLOG",  "PUMPS",  "SPORTS EQUIPMENT"]
  }

  let renderedCats = []

  const fetchImage = (slot, data, hierarchy) => {
    let finaldata;

    const categoryObjectMap = new Map()

    // console.log("categoryObjectMap", categoryObjectMap)

    data.forEach(element => {
      if(!categoryObjectMap.has(element.productCategory)) {
        categoryObjectMap.set(element.productCategory,element)
      }
    });

    if (slot === 0) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category) && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)
          break;
        } 
      }
    } else if (slot === 1) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category)  && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)

          break;
        } 
      }
    } else if (slot === 2) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category)  && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)

          break;
        } 
      }
    } else if (slot === 3) {
      for (const category of hierarchy) {
        if (categoryObjectMap.has(category)  && !renderedCats.includes(category)) {
          finaldata = categoryObjectMap.get(category)
          renderedCats.push(category)

          break;
        } 
      }
    } else {
      finaldata = null
    }

// console.log("renderedCats", renderedCats)
  return <>{imageTemplate(finaldata, slot)}</>

  }

  const imageTemplate = (productData, prodIndex) => {
    // console.log("type : ", productData?.type, "slot : ", prodIndex)
    return (
      <>
        {productData != null && productData.media.map((imageUrl, imgIndex) => (
          <>
            {imageUrl.type === "PrimaryImage" && (
              <img
                src={
                  imageUrl.url.split("?fmt=webp-alpha")[0] +
                  "?$rl_5x7_pdp$&fmt=webp-alpha"
                }
                alt="img"
                className={`img-fluid  ${getClassName(prodIndex)}`}
              />
            )}
          </>
        ))}
        {
          productData === null && <></>
        }
      </>
    );
  };

  const headText = product.name;
  const words = headText.split(" ");

  return (
    <div className={`box col-7 ${productIndex === 0 && 'mcat-box-ms'} ${productIndex === (cardLength-1) && 'mcat-box-me'}`}>
      <div>
        <span className="textLook">
          {"Look " + `${productIndex + 1}` + " of " + cardLength}
        </span>
      </div>
      {words.length >= 2 ? (
        <div className="summerTextContainer">
          <span className="summerText">
          {/* <Typewriter text={''} delay={delay} isAnswerComplete={isAnswerComplete} setLoading={setLoading} effects={effects}
          convoId={convoId} type='productDetail'  productIndex={0} propertyKey={"name"}/> */}
            {words[0]}
            <br />
            {/* <Typewriter text={words[1]} delay={delay} isAnswerComplete={isAnswerComplete} setLoading={setLoading} effects={effects}
          convoId={convoId} type='productDetail'  productIndex={0} propertyKey={"name"}/> */}
            {words[1]}
            <br />

            {words[2]}
          </span>
        </div>
      ) : (
        <div className="summerTextContainer">
          <span className="summerText">
          {/* <Typewriter text={headText} delay={delay} isAnswerComplete={isAnswerComplete} setLoading={setLoading} effects={effects}
          convoId={convoId} type='productDetail'  productIndex={0} propertyKey={"name"}/> */}
          {headText}
          </span>
        </div>
      )}
        <div>
          <>
          {fetchImage(0,product.products,slotHierarchy.slot0)}

          </>

          <>


          {fetchImage(1,product.products,slotHierarchy.slot1)}

          </>

          <>

          {fetchImage(2,product.products,slotHierarchy.slot2)}

          </>

          <>


          {fetchImage(3,product.products,slotHierarchy.slot3)}

          </>
        </div>

      <div className="bottomTextContainer">
        <span className="bottomText">+ View Look</span>
      </div>
    </div>
  );
}

export default MultiProductCard;
