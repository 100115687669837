export const getTitle = (x) => {
    let title = ""
    if(x) {
        if (x.name && x.name !== "" && x.name !== null && x.name !== undefined) {
            title = x.name
            // color name appended
            // if (x.colorOptions && x.colorOptions !== null && x.colorOptions !== undefined) {
            //     title = x.name + " - " + x.colorOptions[0]
            // }
        }
    }
    return title
}

export const getFilteredImage = (selectedImage, data) => {
    let img = []
    if(data && data !== undefined && data[0] !== undefined){
        selectedImage.map(selectImage => {
            data[0].media.filter(item => {
                if(item.type ==="Image" && item.url !== selectImage.url) {
                    img.push(item)
                }}
            )
        })
    }
    return img
}

export const getFilteredImageSpecific = (selectedImage, data) => {   
    let img = []
    selectedImage.map(selectImage => {
        data.media.filter(item => {
            if(item.type ==="Image" && item.url !== selectImage.url) {
                img.push(item)
            }}
        )
    })
    return img
}

export const resetSummedresponse = () => {
    return {
        outfitUrl:'',
        responseType: "",
        responseTitle: '',
        responseText: '',
        productCollections : [],
        products: [],
        categories: [],
        branding: [],
        responseConclusion: '',
        suggestedPrompts: [],
        messageId: "",
    };
};

export const updateSummedResponse = (currentResponse, response) => {
    if (response.outfitUrl) {
        currentResponse = { ...currentResponse, outfitUrl: response.outfitUrl };
    }
    if (response.branding) {
        currentResponse = { ...currentResponse, branding: response.branding };
    }
    if (response.productCollections) {
        currentResponse = { ...currentResponse, productCollections: [...currentResponse.productCollections,...response.productCollections] };
    }
    if (response.products) {
        currentResponse = { ...currentResponse, products: response.products };
    }
    if (response.responseConclusion) {
        currentResponse = {
            ...currentResponse,
            responseConclusion: currentResponse.responseConclusion + response.responseConclusion,
        };
    }
    if (response.responseText) {
        currentResponse = {
            ...currentResponse,
            responseText: currentResponse.responseText + response.responseText,
        };
    }
    if (response.responseTitle) {
        currentResponse = {
            ...currentResponse,
            responseTitle: response.responseTitle,
        };
    }
    if (response.responseType) {
        currentResponse = {
            ...currentResponse,
            responseType: response.responseType,
        };
    }
    if (response.messageId) {
        currentResponse = {
            ...currentResponse,
            messageId: response.messageId,
        };
    }
    if (response.suggestedPrompts) {
        currentResponse = {
            ...currentResponse,
            suggestedPrompts: response.suggestedPrompts,
        };
    }
    return currentResponse;
}