import React from 'react'

 function DeleteModal({visibleModal,handleClose, handleDelete}) {

    if(!visibleModal) return null;


   
  return (
   
    <div> {visibleModal &&
        <div className="modal d-flex fade show mainContainer" id="myModal" role="dialog" style={{display:"block"}} tabindex="-1" aria-hidden="true" >
        <div className="modal-dialog"  style={{borderRadius:"10px"}}>
          <div className="modal-content">
            <div className='modal-content_inner'> 
                <div className="modal-header cross">
                <button type="button" className="closebtn" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}><img src="./static/assets/Close_icon.svg" className="close_icon"/></button>                                         
                </div>
    
                <div className="modal-body">    
                  <div className='modal-body_title'>
                    <span className='modal-body_headingText'>Are you sure you want to delete the chat?</span>
                  </div>
    
                <div className='modal-body_suggestedFeedback modal_body_title2'>
                <p>By deleting the chat this message will no longer be available for access.</p>
                </div>
    
    
                <div className="d-flex justify-content-center"style={{paddingTop:"30px"}}>
                    <button className='btnChat delete-btn' onClick={handleDelete}>delete chat</button>
                    <button className='btnChat cancel-btn' style={{marginLeft:"10px"}} onClick={handleClose}>Cancel</button>
                  </div>
                </div>
    
                                    
            </div>
          </div>
        </div>
      </div>
  }</div>
    
  )
}

export default DeleteModal;
