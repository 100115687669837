import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { getTitle } from "../../Services/CommonServices/helper";
import Typewriter from "../../CommonComponents/TypeWriting";

function ProductCard(props) {
    const [isType, setIsType] = useState(false);
    const [isName, setIsName] = useState(false);
    const [isDescription, setIsDescription] = useState(false);
    const [showTruncate, setShowTruncate]= useState(false);
    const [isIntroText, setIsIntroText] = useState(false);

    const loading = useSelector((state) => state.common.isLoader)
    // const desc_length = props.product.description.length;

    const getSCImage = (img) => {
        let selectedImage = []
        // selectedImage = img && img.length>0 && img.filter(item => (item.url.match(/lifestyle\?fmt=webp-alpha$/) && item.type === "PrimaryImage"));
        // if(selectedImage.length === 0) {
        //   selectedImage = img && img.length>0 && img.filter(item => ((item.url.match(/alternate3\?fmt=webp-alpha$/) || item.url.match(/alternate5\?fmt=webp-alpha$/) || item.url.match(/alternate4\?fmt=webp-alpha$/))));
        // }
        // if(selectedImage.length === 0) {
        //   selectedImage = img && img.length>0 && img.filter(item => item);
        // }
        // selectedImage = selectedImage > 1 ? selectedImage.slice(1) : selectedImage
        
        selectedImage = img && img.length>0 && img.filter(item => item.type === "PrimaryImage");
 
         return (
          <div className="brandingcss">
          {
            selectedImage && selectedImage.length > 0 ? ( <img
            className="img-res"
            src={selectedImage[0].url !== "" ? selectedImage[0].url.split("?fmt=webp-alpha")[0]+"?$rl_4x5_pdp$&fmt=webp-alpha": "./static/assets/no-image.png"}
            alt="no img"
          />
          ) : (
            <img
            className=" img-res"
            src="./static/assets/no-image.png"
            width="220"
            height="275"
            alt="no img"
            />
          )
          }
          </div>
        )
    }

    useEffect(() => {
        if(isName) {
         props.onCardFinish();   
        }
    }, [isName])

    const handleClick = (product, type) => {
        if (type === "quickshop") {
            window.open(product.url, "_blank", "noopener,noreferrer");
        } else if (type === "search") {
            let data = {
            text: "Can you please show me the "+getTitle(product)+"?",
            query: "Can you please show me product ID "+product.id+"?",
            type: type,
            };
            props.onValueChange(data);
        } else if (type === "refresh") {
            let data = {
            text: "Please show me alternatives for "+product.name+"?",
            type: type,
            };
            props.onValueChange(data);
        }
    };

    return (
        <div className="product_card">
            <div id={`productCard-${props.product.id+props.index}`} className= "row my-item" key={props.index}>     
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ps-md-2 pe-4 align-self-start">
                    {/* <div className={`leJeuneDeck product-count ${props.index > 0 && "ms-sm-1"} ${props.index === 0 && "count1"}`}>
                        {props.index + 1}
                    </div> */}
                    <div className="row d-flex align-items-start sc-div">
                        <div className="sc-subdiv d-flex"> 
                        <div className="col-6 col-md-6 col-lg-4 g-0">
                            <div className="d-flex align-items-start">
                            {props.product.media && props.product.media.length > 0 && (
                                <div className="d-flex sc-img-div">
                                {getSCImage(props.product.media)}
                                </div>
                            // ) : (
                            //     <div className="d-flex">
                            //     <img
                            //         key={props.product.id}
                            //         className="img-fluid img-res"
                            //         src="./static/assets/no-image.png"
                            //         width="200"
                            //         height="200"
                            //         alt="no img" 
                            //     />
                            //     </div>
                            )}
                            </div>
                        </div>
                            <div className="col-6 col-md-6 col-lg-7 ms-1 ms-sm-4 content-div ms-lg-4 pe-md-4 pe-lg-0 align-self-center">                
                                <div className="d-flex text-start flex-column justify-content-center">                                  
                                    <div>
                                        {/* {!props.isCategory && (                                    */}
                                            <div className="foundersGroteskTextMedium text-uppercase product-cat-Text">
                                                <Typewriter convoId={props.convoId}  productIndex={props.index} text={props.isCategory ? `OPTION ${props.index + 1}` : props.product.type} delay={20} isAnswerComplete={true} setLoading={setIsType} propertyKey={"type"} effects={props.effects}
                                                isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered} type='product'/>
                                            </div>                                        
                                        {/* )}   */}
                                        {
                                            isType && 
                                            <div className="product-header-title text-uppercase  pe-0">
                                                <Typewriter convoId={props.convoId}  productIndex={props.index} text={props.product.name} delay={20} isAnswerComplete={true} setLoading={setIsName} propertyKey={"name"} effects={props.effects}
                                                isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered} type='product'/>
                                            </div>  
                                        }
                                        {/* {
                                            isName && 
                                            <div
                                                onClick={() => {setShowTruncate(!showTruncate);}}
                                                className={`leJeuneDeck product-detail truncate pe-3 
                                                    ${showTruncate ? "show-truncate-text" : ""} 
                                                    ${desc_length > 82 && "xs_cursor"}
                                                    ${desc_length > 157 && "sm_cursor"}
                                                    ${desc_length > 95 && "md_cursor"}
                                                    ${desc_length > 141 && "mdcursor"}
                                                    ${desc_length > 236 && "cursor"}
                                                    ${desc_length > 313 && "xl_cursor"}
                                                    ${desc_length > 352 && "xxl_cursor"}
                                                    `}
                                            >
                                                <Typewriter convoId={props.convoId}  productIndex={props.index} text={props.effects === true ? props.product?.description?.slice(0,360): props.product.description} delay={20} isAnswerComplete={true} setLoading={setIsDescription} propertyKey={"description"} effects={props.effects}
                                                isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered} type='product'/>                                        
                                            </div>
                                        } */}
                                        {
                                            isName &&
                                            <div className="btn--animated">
                                                <div className="badge text-wrap d-flex ps-0 mb-md-5 mb-lg-4 mb-xl-3 mb-xxl-0">
                                                    <button class={`utility-btn ${!loading && 'utility-btn-hover'}`} disabled={loading} onClick={() => {handleClick(props.product, "search")}}> <img className="utility_icons" src="/static/assets/details_icon.svg" alt="details_icon"/><span className="d-none d-lg-inline">Details</span></button>

                                                    <button class={`utility-btn ${!loading && 'utility-btn-hover'}`} disabled={loading} onClick={() => {handleClick(props.product, "refresh")}}> <img className="utility_icons styles_icon" src="/static/assets/similarstyle_icon.svg" alt="similarstyle_icon"/> <span className="d-none d-lg-inline">Similar Styles</span> </button>

                                                    <button class="utility-btn utility-btn-hover" onClick={() => {handleClick(props.product, "quickshop")}}> <img className="utility_icons shop_icon" src="/static/assets/shop_icon.svg" alt="shop_icon"/> <span className="d-none d-lg-inline"> Shop</span></button>
                                                </div>
                                            </div>    
                                        } 
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;