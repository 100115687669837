const multiProduct =
    {
        "responseType": "ProductCollectionListing",
        "responseText": "A nasy blazer liiay be the single rnost versat ile itelji you can have in your closet. It's also one of Ralphs most ubiquitous icons. Heres how you car style for tnany different occasions:",
        "responseConclusion": "Regardless of which look you select, ensure it's freshly laundered and ironed for the wedding day to look your best. If pairing with a linen suit, the crispness of these shirts will offer a great balance to the relaxed texture of the suit.",
        "suggestedPrompts": [
            "How do style this jacket?",
            "I'd like find something to pair this with?"
        ],
        "productCollections": [
            {
                "id": "collection-1",
                "name": "HAMPTON WEDDING",
                "products": [
                    {
                        "url": "https://ralphlauren.com/MEN-CLOTHING-SUITS/POLO-TAILORED-LINEN-TUXEDO/0077736650.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-AI715953320001_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate10?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate7?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate5?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate8?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate6?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate4?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 1,
                        "id": "715953320",
                        "type": "TAILORED CLOTHING",
                        "name": "Polo Tailored Linen Tuxedo",
                        "description": "Our Polo tuxedo offers more structure in the chest and shoulders along with a full lining. This version pairs with flat-front trousers and is tailored in Italy with luxuriously lightweight linen.",
                        "price": 1498.0,
                        "productCategory": "TAILORED CLOTHING",
                        "productClass": "2 PC SUIT",
                        "productSubClass": "SINGLE BREASTED"
                    },
                    {
                        "url": "https://ralphlauren.com/0077750685.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-AI712936020001_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate16?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate10?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate4?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate15?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 3,
                        "id": "712936020",
                        "type": "SHIRTS",
                        "name": "Custom Fit Poplin Shirt",
                        "description": "Crafted with 120s-quality, two-ply cotton poplin, this crisp shirt features a trim fit and our signature embroidered Pony at the hem.",
                        "price": 168.0,
                        "productCategory": "SHIRTS",
                        "productClass": "DRESS SHIRT",
                        "productSubClass": "LONG SLEEVE"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-FOOTWEAR-SHOES/BOOTH-CALFSKIN-LOAFER/0041482415.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-1323021_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate2?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 5,
                        "id": "811735597",
                        "type": "FOOTWEAR",
                        "name": "Booth Calfskin Loafer",
                        "description": "Handmade in Spain, the Booth loafer celebrates traditional craftsmanship and refined style. Supple full-grain leather is paired with a custom cork insole designed for comfort. With Goodyear welt construction, the Booth can be resoled by a skilled cobbler again and again for years of regular wear.",
                        "price": 350.0,
                        "productCategory": "DRESS SHOE",
                        "productClass": "WINGTIP",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-ACCESSORIES-TIES-POCKET-SQUARES/STRIPED-SILK-REPP-NARROW-TIE/0046049821.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1464637_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 6,
                        "id": "712883563",
                        "type": "NECKWEAR",
                        "name": "Striped Silk Repp Narrow Tie",
                        "description": "Exceptional fabrics, exclusive designs, and fine Italian craftsmanship are the hallmarks of our woven ties. Each one is hand-cut on a perfect 45-degree bias to ensure that it never twists or turns.",
                        "price": 125.0,
                        "productCategory": "NECKWEAR",
                        "productClass": "NECK TIE",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/0077753796.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712951025001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 6,
                        "id": "712951025",
                        "type": "NECKWEAR",
                        "name": "Paisley Silk Crepe Pocket Square",
                        "description": "This madder-style pocket square is expertly crafted in Italy with luxurious silk crepe that was custom-developed with Mantero, an established mill in Como renowned for its striking prints. It is finished with hand-rolled edges.",
                        "price": 95.0,
                        "productCategory": "NECKWEAR",
                        "productClass": "POCKET SQUARE",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-ACCESSORIES-BELTS/LEATHER-DRESS-BELT/0077383693.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI405931715001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI405931715001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 8,
                        "relevancyScore": 0,
                        "id": "405931715",
                        "type": "ACCESSORIES",
                        "name": "Leather Dress Belt",
                        "description": "Crafted in Italy with vegetable-tanned leather, this topstitched belt is finished with a streamlined metal buckle.",
                        "price": 120.0,
                        "style": "CLASSIC, SMART CASUAL, WORKWEAR",
                        "color": "BLACK",
                        "productCategory": "BELTS",
                        "productClass": "BELT",
                        "productSubClass": "MEDIUM",
                        "seasons": "FALL, SPRING",
                        "colorPattern": "SOLID",
                        "colorOptions": [
                            "BLACK",
                            "BROWN"
                        ],
                        "dressCode": "SMART CASUAL/BUSINESS CASUAL",
                        "gender": "MENS"
                    }
                ]
            },
            {
                "id": "collection-2",
                "name": "Timeless chic",
                "products": [
                    {
                        "url": "https://ralphlauren.com/MEN-CLOTHING-SUITS/POLO-TAILORED-LINEN-TUXEDO/0077736650.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-AI715953320001_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_lifestyle?fmt=webp-alpha", //full suit
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate10?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate7?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate5?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate8?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate6?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate4?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 1,
                        "id": "715953320",
                        "type": "TAILORED CLOTHING",
                        "name": "Polo Tailored Linen Tuxedo",
                        "description": "Our Polo tuxedo offers more structure in the chest and shoulders along with a full lining. This version pairs with flat-front trousers and is tailored in Italy with luxuriously lightweight linen.",
                        "price": 1498.0,
                        "productCategory": "TAILORED CLOTHING",
                        "productClass": "2 PC SUIT",
                        "productSubClass": "SINGLE BREASTED"
                    },
     
     
                    {
                        "url": "https://ralphlauren.com/0077750685.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-AI712936020001_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_lifestyle?fmt=webp-alpha", //shirt
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate16?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate10?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate4?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate15?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 3,
                        "id": "712936020",
                        "type": "SHIRTS",
                        "name": "Custom Fit Poplin Shirt",
                        "description": "Crafted with 120s-quality, two-ply cotton poplin, this crisp shirt features a trim fit and our signature embroidered Pony at the hem.",
                        "price": 168.0,
                        "productCategory": "SHIRTS",
                        "productClass": "DRESS SHIRT",
                        "productSubClass": "LONG SLEEVE"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-FOOTWEAR-SHOES/BOOTH-CALFSKIN-LOAFER/0041482415.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-1323021_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_lifestyle?fmt=webp-alpha", //Shoe
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate2?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 5,
                        "id": "811735597",
                        "type": "FOOTWEAR",
                        "name": "Booth Calfskin Loafer",
                        "description": "Handmade in Spain, the Booth loafer celebrates traditional craftsmanship and refined style. Supple full-grain leather is paired with a custom cork insole designed for comfort. With Goodyear welt construction, the Booth can be resoled by a skilled cobbler again and again for years of regular wear.",
                        "price": 350.0,
                        "productCategory": "DRESS SHOE",
                        "productClass": "WINGTIP",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {  
                        "url": "https://ralphlauren.com/MEN-ACCESSORIES-TIES-POCKET-SQUARES/STRIPED-SILK-REPP-NARROW-TIE/0046049821.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1464637_lifestyle?fmt=webp-alpha", //tie
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 6,
                        "id": "712883563",
                        "type": "NECKWEAR",
                        "name": "Striped Silk Repp Narrow Tie",
                        "description": "Exceptional fabrics, exclusive designs, and fine Italian craftsmanship are the hallmarks of our woven ties. Each one is hand-cut on a perfect 45-degree bias to ensure that it never twists or turns.",
                        "price": 125.0,
                        "productCategory": "NECKWEAR",
                        "productClass": "NECK TIE",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/0077753796.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712951025001_lifestyle?fmt=webp-alpha", // Pocket Square
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 6,
                        "id": "712951025",
                        "type": "POCKETSQUARE",
                        "name": "Paisley Silk Crepe Pocket Square",
                        "description": "This madder-style pocket square is expertly crafted in Italy with luxurious silk crepe that was custom-developed with Mantero, an established mill in Como renowned for its striking prints. It is finished with hand-rolled edges.",
                        "price": 95.0,
                        "productCategory": "POCKETSQUARE",
                        "productClass": "POCKET SQUARE",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-ACCESSORIES-BELTS/LEATHER-DRESS-BELT/0077383693.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI405931715001_lifestyle?fmt=webp-alpha", //belt
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI405931715001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 8,
                        "relevancyScore": 0,
                        "id": "405931715",
                        "type": "ACCESSORIES",
                        "name": "Leather Dress Belt",
                        "description": "Crafted in Italy with vegetable-tanned leather, this topstitched belt is finished with a streamlined metal buckle.",
                        "price": 120.0,
                        "style": "CLASSIC, SMART CASUAL, WORKWEAR",
                        "color": "BLACK",
                        "productCategory": "BELTS",
                        "productClass": "BELT",
                        "productSubClass": "MEDIUM",
                        "seasons": "FALL, SPRING",
                        "colorPattern": "SOLID",
                        "colorOptions": [
                            "BLACK",
                            "BROWN"
                        ],
                        "dressCode": "SMART CASUAL/BUSINESS CASUAL",
                        "gender": "MENS"
                    }
                ]
            },
            {
                "id": "collection-3",
                "name": "Classic Elegance",
                "products": [
                    {
                        "url": "https://ralphlauren.com/MEN-CLOTHING-SUITS/POLO-TAILORED-LINEN-TUXEDO/0077736650.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-AI715953320001_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate10?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate7?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate5?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate8?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate6?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI715953320001_alternate4?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 1,
                        "id": "715953320",
                        "type": "TAILORED CLOTHING",
                        "name": "Polo Tailored Linen Tuxedo",
                        "description": "Our Polo tuxedo offers more structure in the chest and shoulders along with a full lining. This version pairs with flat-front trousers and is tailored in Italy with luxuriously lightweight linen.",
                        "price": 1498.0,
                        "productCategory": "TAILORED CLOTHING",
                        "productClass": "2 PC SUIT",
                        "productSubClass": "SINGLE BREASTED"
                    },
                    {
                        "url": "https://ralphlauren.com/0077750685.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-AI712936020001_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate16?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate10?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate4?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate15?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712936020001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 3,
                        "id": "712936020",
                        "type": "SHIRTS",
                        "name": "Custom Fit Poplin Shirt",
                        "description": "Crafted with 120s-quality, two-ply cotton poplin, this crisp shirt features a trim fit and our signature embroidered Pony at the hem.",
                        "price": 168.0,
                        "productCategory": "SHIRTS",
                        "productClass": "DRESS SHIRT",
                        "productSubClass": "LONG SLEEVE"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-FOOTWEAR-SHOES/BOOTH-CALFSKIN-LOAFER/0041482415.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/content/PoloGSI/s7-1323021_rlvideo",
                                "type": "Video",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate3?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1323021_alternate2?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 5,
                        "id": "811735597",
                        "type": "FOOTWEAR",
                        "name": "Booth Calfskin Loafer",
                        "description": "Handmade in Spain, the Booth loafer celebrates traditional craftsmanship and refined style. Supple full-grain leather is paired with a custom cork insole designed for comfort. With Goodyear welt construction, the Booth can be resoled by a skilled cobbler again and again for years of regular wear.",
                        "price": 350.0,
                        "productCategory": "DRESS SHOE",
                        "productClass": "WINGTIP",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-ACCESSORIES-TIES-POCKET-SQUARES/STRIPED-SILK-REPP-NARROW-TIE/0046049821.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-1464637_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 6,
                        "id": "712883563",
                        "type": "NECKWEAR",
                        "name": "Striped Silk Repp Narrow Tie",
                        "description": "Exceptional fabrics, exclusive designs, and fine Italian craftsmanship are the hallmarks of our woven ties. Each one is hand-cut on a perfect 45-degree bias to ensure that it never twists or turns.",
                        "price": 125.0,
                        "productCategory": "NECKWEAR",
                        "productClass": "NECK TIE",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/0077753796.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI712951025001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 6,
                        "id": "712951025",
                        "type": "NECKWEAR",
                        "name": "Paisley Silk Crepe Pocket Square",
                        "description": "This madder-style pocket square is expertly crafted in Italy with luxurious silk crepe that was custom-developed with Mantero, an established mill in Como renowned for its striking prints. It is finished with hand-rolled edges.",
                        "price": 95.0,
                        "productCategory": "NECKWEAR",
                        "productClass": "POCKET SQUARE",
                        "productSubClass": "NO SUBCLASS"
                    },
                    {
                        "url": "https://ralphlauren.com/MEN-ACCESSORIES-BELTS/LEATHER-DRESS-BELT/0077383693.html",
                        "currency": "USD",
                        "media": [
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI405931715001_lifestyle?fmt=webp-alpha",
                                "type": "PrimaryImage",
                                "coverPhotoUrl": ""
                            },
                            {
                                "url": "https://ralphlauren.scene7.com/is/image/PoloGSI/s7-AI405931715001_alternate1?fmt=webp-alpha",
                                "type": "Image",
                                "coverPhotoUrl": ""
                            }
                        ],
                        "order": 8,
                        "relevancyScore": 0,
                        "id": "405931715",
                        "type": "ACCESSORIES",
                        "name": "Leather Dress Belt",
                        "description": "Crafted in Italy with vegetable-tanned leather, this topstitched belt is finished with a streamlined metal buckle.",
                        "price": 120.0,
                        "style": "CLASSIC, SMART CASUAL, WORKWEAR",
                        "color": "BLACK",
                        "productCategory": "BELTS",
                        "productClass": "BELT",
                        "productSubClass": "MEDIUM",
                        "seasons": "FALL, SPRING",
                        "colorPattern": "SOLID",
                        "colorOptions": [
                            "BLACK",
                            "BROWN"
                        ],
                        "dressCode": "SMART CASUAL/BUSINESS CASUAL",
                        "gender": "MENS"
                    }
                ]
            },
        ]
    }
export default multiProduct; 