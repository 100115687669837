import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from 'react-redux';
import { loader,isResponseStopped,userPrompt, stopStreaming, appFeedBackModal } from '../Services/Redux/Reducers/commonSlice';
import { apiError, conversationList, likeApiError,initialSessionId, appLevelFeedback } from '../Services/Redux/Reducers/chatSlice';
import { addchat, likeordislike, fetchMsg, fetchMsgFromMicrosoft, fetchNextMsg, fetchPreviousMsg, uniqueSessionHistory, getAppFeedBack } from "../Services/ApiServices/chatServices";
import ChatAPIService from "../Services/ChatAPIService";
import { resetSummedresponse, updateSummedResponse } from "../Services/CommonServices/helper";
import MessageHistory from "../CommonComponents/MessageHistory";
import { css } from '@emotion/react'
import ScrollToBottom,{useScrollToBottom,useScrollToEnd} from 'react-scroll-to-bottom';
import cloneDeep from 'lodash/cloneDeep'
import LikeAndDislike from "../CommonComponents/LikeAndDislike";
import multiProduct from "../Services/DataTemplate/MultiCatProductTemplate";


const ROOT_CSS = css({
  height: 600,
  width: 400
});

function Chat() {

  

  // const savedlocalStorageData = JSON.parse(localStorage.getItem('persist_session'))
  const input = useSelector((state) => state.common.userPrompt)
  const [error, setError] = useState("");
  const { accounts } = useMsal();
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const inputRef =useRef(null);
  const [isHeight, setHeight] = useState(false)
  const [isPlaceHolderVisible, setIsPlaceHolderVisible] =useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] =useState(true);
  const [overallChatLength, setOverallChatLength] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sessionId, setSessionId] = useState(sessionStorage.getItem('persist_session') ?sessionStorage.getItem('persist_session') : null);
  const loading = useSelector((state) => state.common.isLoader)
  const responseStop = useSelector((state) => state.common.isResponseStopped)
  const dispatch = useDispatch();
  const streamedResponse = useRef(resetSummedresponse());
  const [isResponseComplete, setIsResponseComplete]=useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const conList = useSelector((state) => state.chat.conversationList);
  const isApiError = useSelector((state) => state.chat.apiError);
  const [isScreenResponsive, SetIsScreenResponsive] = useState(false);
  const [reqRespId, setReqRespId] = useState("");
  const effectRan = useRef(false);
  const [abortController, setAbortController] = useState(null);
  const loggedUserIp = useSelector((state) => state.common.userIp);
  const userDetails = useSelector((state) => state.common.loggedUserDetails)
  const [sessionChangeStatus, setSessionChangeStatus] = useState();
  const [lastConvoId, setLastConvoId] = useState("");
  const [isStopResponseTriggered, seIsStopResponseTriggered] = useState(false);
  const scrollToBottom = useScrollToBottom();
  const [isUserScrolled, setIsuserScrolled] = useState(false);
  const timeOutRef = useRef(null);
  const initialSessionIdData = useSelector((state) => state.chat.initialSessionId)
  const[data,setData] = useState([])
  const[isRefresh,setIsRefresh] = useState(false)
  // if(sessionId){
  //   localStorage.setItem('persist_session', JSON.stringify(sessionId))
  // }

// if(data){
//   dispatch(conversationList(data));
// }

// changes done by Bhavna for scroll

  // const handleUserScroll=(scrolling)=>{
  //   if(!scrolling.isAtBottom && !isUserScrolled){
  //     setIsuserScrolled(true);
  //   }
  // }
 
  // useEffect(() => {  
  //   if(!isUserScrolled && loading)  {
  //   timeOutRef.current = setTimeout(() => {
  //     scrollToBottom();
  //   }, 10);
  // }
 
  //   return () => clearTimeout(timeOutRef.current);
 
  // }, [loading,isUserScrolled])


  // used for unsubscribe the modal
  dispatch(appFeedBackModal(false))
  const handleFocus=()=>{
    setIsPlaceHolderVisible(false);
  }

  const onHandleInputChange =(e)=>{
    const inputValue = e.target.value
    dispatch(userPrompt(inputValue));
    setIsSendButtonDisabled(inputValue.trim()==='');
    if(inputValue.length > 136 || inputValue.length ) {
      setHeight(true)
    }
    else{
      setHeight(false)
    }
  }
  
  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsPlaceHolderVisible(input === '' || !input);
    }
  };

  useEffect(()=> {
    if(accounts.length !== 0) {
      if(accounts[0].name.includes(',')){
        let firstname = accounts[0].name.split(',')[1];
        firstname = firstname.split(' ')[1];
        let lastname = accounts[0].name.split(',')[0];
        setName(firstname+" "+lastname);
        setShortName(firstname.charAt(0)+lastname.charAt(0));
      } else {
        let firstname = accounts[0].name;
        setName(firstname);
        setShortName(firstname.charAt(0));
      }
    }
}, [accounts])

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [input]);



  useEffect(() => {
    // getChat();
    if (!effectRan.current) {
      console.log("effect applied - only on the FIRST mount");
      if(input) { 
        streaming(input);
      }
    }
    if(sessionId!= null){
      storeOldMessage();
      app_feedback()
    }
    // app_feedback()
    return () => effectRan.current = true;
  }, []);
  
  // for input expansion 
  useEffect(() => {
    inputRef.current.style.height = "0px";
      const scrollHeight = inputRef.current.scrollHeight;
      // inputRef.current.style.height = scrollHeight + "px";
      //condition to set input height
      
      if(isHeight === true){
        inputRef.current.style.height = (scrollHeight) + "px";
      }else{
        inputRef.current.style.height = (scrollHeight - 10) + "px";
      }
  }, [input]);

  //iphone height fix 
  useEffect(()=> {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    let userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ((macosPlatforms.test(userAgent)) || (windowsPlatforms.test(userAgent)) || ((/android/.test(userAgent)) && !safari )){
      SetIsScreenResponsive(true);
      // console.log((/android/.test(userAgent)),"android");
      // console.log((windowsPlatforms.test(userAgent)),"windows");
      // console.log((macosPlatforms.test(userAgent)),"mac");
      // console.log(safari,"safari");
    }
  },[]);

  // useEffect(()=>{
  //   if(childVal) {
  //     streaming(childVal);
  //   }
  // },[abortController])


  const storeOldMessage = () => {
    // const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
    const params = [accounts && accounts[0].username, sessionId];
    // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
    uniqueSessionHistory(
      params,
      (response) => {
        // Chat History storing the messages
        setData(response.data.data);
        dispatch(conversationList(response.data.data));
        dispatch(stopStreaming(false))
      },
      (err) => {
        dispatch(apiError(err.message));
        dispatch(loader(false));
      }
    );
  };


  //For Feedback check
  const app_feedback = () => {
    // const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
    const params = [accounts && accounts[0].username];
    // const params = ['shubhangichainsing.thakur@ralphlauren.com'];
    // const params = [userId, JSON.stringify(sessionId)];
    // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
    getAppFeedBack(
      params,
      (response) => {
        // appLevel data 
        dispatch(appLevelFeedback(response.data))
      },
      (err) => {
        dispatch(apiError(err.message));
        dispatch(loader(false));
      }
    );
  };



  const getPreviousChat = () => {
    setIsStreaming(false);
    const params = {
      account : accounts[0]?.username,
      offset: offset
    }
    fetchPreviousMsg(params, (response) => {
      dispatch(conversationList(response.data.data));
      setOffset(response.data.offset)
    }, (err) => {

    })
  }

  const getNextChat = () => {
    setIsStreaming(false);
    if(parseInt(offset)+20 <= overallChatLength) {
      const params = {
        account : accounts[0]?.username,
        offset: offset
      }
      fetchNextMsg(params, (response) => {
        dispatch(conversationList(response.data.data));
        setOffset(response.data.offset)
      }, (err) => {

      })
    }
  }

  const getChat = () => {
    setIsStreaming(false);
    fetchMsg(accounts[0]?.username, (response) => {
      dispatch(conversationList(response.data.data));
      setOffset(response.data.offset)
      setOverallChatLength(response.data.overallChatLength)
      handleStopResponse();
    }, (err) => {
      setError(err.message);
      handleStopResponse();
    }); 
  }

  const handleLoader = () => {
    dispatch(loader(true))
    setIsSendButtonDisabled(true);
    setIsPlaceHolderVisible(true);
  };
 
  const handleStopResponse = (isTriggered) => {
    setIsPlaceHolderVisible(true);
    dispatch(loader(false))
    if(abortController && !isTriggered) {
      abortController.abort();
    }
    if (isTriggered) {
      //  console.log(lastConvoId,'lastConvoId')
      localStorage.setItem("stoppedResponseId",  lastConvoId);
      seIsStopResponseTriggered(true);
    }
   
  };
 
  const handleValueChange = (childValue) => {
    dispatch(stopStreaming(true))

    console.log("childValue : ", childValue)
    if(childValue !== true) {

        streamedResponse.current = resetSummedresponse();
        streaming(childValue.text, childValue.query);

        
      //  if(abortController ){
      //     setChildVal(childValue.text)
      //       abortController.abort();
      //   }
    } else {
      handleStopResponse();
    }
  };

  useEffect(() => {
    // for scroll struck
    const timeout = setTimeout(() => {
      let button = document.querySelector(".follow_btn")
      if(button){
        button.click();
      }
    }, 20);
    return () => clearTimeout(timeout);
  }, [loading])

  const storeMessage = (conHis) => {
    //return
    if(localStorage.getItem('storeChat') !== "false") {
      let convo = cloneDeep(conHis)
      let convoList = cloneDeep(conHis)
      let lastItem = convo.pop();
      const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
      addchat(lastItem,(response) => { // Chat History storing the messages     
        convoList[conIndex]['showLikeDislike'] = false
        convoList[conIndex]['error'] = "Oops, Something went wrong"
        dispatch(conversationList(convoList));
      }, (err) => {
        // convoList[conIndex]['error'] = err.message
        // dispatch(conversationList(convoList));
        dispatch(apiError(err.message));
        dispatch(loader(false));
      });
    } else {
      localStorage.setItem('storeChat', true)
    }
  }

  const streaming = async(input, query="") => {
    localStorage.setItem("stoppedResponseId", null);
    dispatch(isResponseStopped(false));
    const controller = new AbortController();
    setAbortController(controller);
    dispatch(likeApiError(true));
    dispatch(apiError(false));
    let sessionChange = false
    setSessionChangeStatus();
    // setIsuserScrolled(false); // Bhavna changes for scroll
    let button = document.querySelector(".follow_btn")
    if(button){
      button.click();
    }
    if (loading) return;
    if (input === undefined || input === '' || input === null) {
        return;
    }
    handleLoader();
    streamedResponse.current = resetSummedresponse();
    let resetdata = resetSummedresponse();
    setIsStreaming(true);
    let lastGeneratedId = crypto.randomUUID().slice(2)
    setLastConvoId(lastGeneratedId);
    let userParam = {
      uid: lastGeneratedId,
      reqRespId: "",
      requestDateTime: new Date().toString(),
      userId: accounts[0]?.username,
      messageId: "",
      question: input,
      response: {},
      sessionId: sessionId,
      sessionChange: sessionChange,
      showLikeDislike: false,
      error: "",
      likeStatus: "",
      feedBack: "",
      active: true,
    }
    let conHistory = cloneDeep(conList)
    conHistory.push(userParam)
    dispatch(userPrompt(''));

    setError("");
    dispatch(conversationList(conHistory));
    setIsResponseComplete(true);
    let iteration = 0;
    try{
        const response = await ChatAPIService.postRequest(query !==""? query:input, sessionId, userDetails.userId, controller.signal)
        // console.log(response,'postcall response')
        var loopRunner = true;
        if (!response?.ok || !response.body) {
            let conHis = cloneDeep(conHistory)
            const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
            conHis[conIndex]['response'] = {
              responseType: "Error",
              errormessage:  `SessionId : ${sessionId}, Error: ${response?.statusText}`,
              error: `${response?.statusText}`
            } 
            dispatch(conversationList(conHis));
            dispatch(isResponseStopped(true));
            storeMessage(conHis)
            loopRunner = false;  
            // setError(`${response?.statusText}`) 
            dispatch(loader(false));
            return;
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        
        var lastChunk = '';
        try{
          while (loopRunner && reader) {
              // iteration+=1
              const { value, done } = await reader.read();
              // if (iteration === 2) {
              //   let conHis = cloneDeep(conHistory)
              //   const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
              //   conHis[conIndex]['response'] = {
              //     responseType: "Error",
              //     errormessage:  "Oops, Something went wrong",
              //     error: "Oops, Something went wrong"
              //   } 
              //   dispatch(conversationList(conHis));
              //   return;
              // }
              if (done) {
                  break;
              }
              const decodedChunk =
                  lastChunk + decoder.decode(value, { stream: true });
              const responseArray = decodedChunk.split('\n\n');
              // console.log(responseArray);
              lastChunk = responseArray.pop() ?? '';
              updateResponseSteam(response,responseArray, conHistory, lastGeneratedId,sessionChange);
              // responseArray.forEach((responsestring) => {
              //   console.log('352',isStopResponseTriggered,lastConvoId)
              //   if(isStopResponseTriggered){
              //     dispatch(isResponseStopped(true));
              //     lastGeneratedId = '';
              //     console.log('if stop')
              //   }
              //   console.log(lastGeneratedId, );
              //   if(!lastGeneratedId){
              //     return;
              //   }
              //     responsestring = responsestring.replace('data:', '').trim();
              //     if (
              //         responsestring === '' ||
              //         responsestring === undefined ||
              //         responsestring === null
              //     ) {
              //         return;
              //     }
              //     if (responsestring === 'DONE') {
              //         return;
              //     }
              //     if (responsestring === 'error') {    // NEW BLOCK TO HANDLE ERROR CHUNK
              //       console.log('response => ', response);
              //       let conHis = cloneDeep(conHistory)
              //       const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
              //       conHis[conIndex]['response'] = {
              //         responseType: "Error",
              //         errormessage:  `SessionId : ${sessionId}, Error: ${response?.statusText}`,
              //         error: `SessionId : ${sessionId}, Error: ${response?.statusText}`
              //       } 
              //       dispatch(conversationList(conHis));
              //       dispatch(isResponseStopped(true));
              //       storeMessage(conHis)
              //       dispatch(loader(false))
              //       return;
              //     }
              //     // console.log(responsestring);
              //     try{
              //         const apiResponseObject =
              //         JSON.parse(responsestring);

              //         const responseObject = JSON.parse(
              //             apiResponseObject.response,
              //         );
              //         if (apiResponseObject.sessionId) {
              //           if(sessionId == null || sessionId !== apiResponseObject.sessionId) {
              //             setSessionId(apiResponseObject.sessionId);
              //             sessionChange = true
              //             setSessionChangeStatus(true)
              //           }
              //         }
              //         streamedResponse.current = updateSummedResponse(
              //             streamedResponse.current,
              //             {...responseObject, messageId: apiResponseObject.activityId},
              //         );
              //         console.log(conList)
              //         let conHis = cloneDeep(conHistory)
              //         let conListHis = cloneDeep(conList)
              //         console.log(conHis, 'conHis', '398',conListHis, streamedResponse.current);
              //         let conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId);
              //         let updatedConIndex = conListHis.findIndex((element) => element.uid === lastGeneratedId);
              //         console.log(updatedConIndex)
              //       //  if(!conHis[conIndex]['response'].responseStopped) {
              //         conHis[conIndex]['messageId'] = streamedResponse.current.messageId
              //         conHis[conIndex]['response'] = streamedResponse.current
              //         conHis[conIndex]['sessionId'] = apiResponseObject.sessionId
              //         conHis[conIndex]['sessionChange'] = sessionChange
              //         dispatch(conversationList(conHis));
              //         //}
              //     }
              //     catch(ex){
              //         let conHis = cloneDeep(conHistory)
              //         const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
              //         conHis[conIndex]['response'] = {
              //           responseType: "Error",
              //           errormessage:  `SessionId : ${sessionId}, Error: ${ex}`,
              //           error: `${ex}`
              //         } 
              //         dispatch(conversationList(conHis));
              //         dispatch(isResponseStopped(true));
              //         storeMessage(conHis)
              //         dispatch(loader(false))
              //         return;
              //     }
              // });
          }
        } catch(ex) {
          // console.log(ex,'ex')
          let conHis = cloneDeep(conHistory)
          const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
          if(ex.toString() === "AbortError: BodyStreamBuffer was aborted") {
            dispatch(isResponseStopped(true));
            conHis[conIndex]['response'] = {
              responseType: "Error",
              errormessage:  "Oops, Request cancelled",
              error: "Oops, Request cancelled"
            } 
            dispatch(conversationList(conHis));
          } else {
            conHis[conIndex]['response'] = {
              responseType: "Error",
              errormessage:  "Oops, Something went wrong",
              error: "Oops, Something went wrong"
            } 
            dispatch(conversationList(conHis));
            dispatch(isResponseStopped(true));
              storeMessage(conHis)           
          }
          dispatch(loader(false))
          return;
        }
    }
    catch(ex){
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        conHis[conIndex]['response'] = {
          responseType: "Error",
          errormessage:  `SessionId : ${sessionId}, Error: ${ex}`,
          error: `${ex}`
        } 
        dispatch(conversationList(conHis));
        dispatch(isResponseStopped(true));
        storeMessage(conHis)
        dispatch(loader(false));
        return;
    }
    setIsResponseComplete(false);
}

  const updateResponseSteam = (response,responseArray, conHistory, lastGeneratedId,sessionChange) => {
    responseArray.forEach((responsestring) => {
      const stoppedResponseId = localStorage.getItem("stoppedResponseId");
      if(stoppedResponseId && lastGeneratedId === stoppedResponseId ){
        return;
      }
        responsestring = responsestring.replace('data:', '').trim();
        if (
            responsestring === '' ||
            responsestring === undefined ||
            responsestring === null
        ) {
            return;
        }
        if (responsestring === 'DONE') {
            return;
        }
        if (responsestring === 'error') {    // NEW BLOCK TO HANDLE ERROR CHUNK
          // console.log('response => ', response);
          let conHis = cloneDeep(conHistory)
          const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
          conHis[conIndex]['response'] = {
            responseType: "Error",
            errormessage:  `SessionId : ${sessionId}, Error: ${response?.statusText}`,
            error: `SessionId : ${sessionId}, Error: ${response?.statusText}`
          } 
          dispatch(conversationList(conHis));
          dispatch(isResponseStopped(true));
          storeMessage(conHis)
          dispatch(loader(false))
          return;
        }
        // console.log(responsestring);
        try{
            const apiResponseObject =
            JSON.parse(responsestring);

            const responseObject = JSON.parse(
                apiResponseObject.response,
            );
            if (apiResponseObject.sessionId) {
              if(sessionId == null || sessionId !== apiResponseObject.sessionId) {
                setSessionId(apiResponseObject.sessionId);
                sessionStorage.setItem('persist_session', apiResponseObject.sessionId)
                sessionChange = true
                setSessionChangeStatus(true)
              }
            }
            streamedResponse.current = updateSummedResponse(
                streamedResponse.current,
                {...responseObject, messageId: apiResponseObject.activityId},
            );
            let conHis = cloneDeep(conHistory)
            // console.log(streamedResponse.current)
            // console.log(conHis, 'conHis', '398', streamedResponse.current?.products);
            let conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId);
            conHis[conIndex]['messageId'] = streamedResponse.current.messageId
            conHis[conIndex]['response'] = streamedResponse.current
            conHis[conIndex]['sessionId'] = apiResponseObject.sessionId
            conHis[conIndex]['sessionChange'] = sessionChange
            dispatch(conversationList(conHis));
            // Microsoft needs this please do not comment
            console.log('SessionID-', apiResponseObject.sessionId)
        }
        catch(ex){
            let conHis = cloneDeep(conHistory)
            const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
            conHis[conIndex]['response'] = {
              responseType: "Error",
              errormessage:  `SessionId : ${sessionId}, Error: ${ex}`,
              error: `${ex}`
            } 
            dispatch(conversationList(conHis));
            dispatch(isResponseStopped(true));
            storeMessage(conHis)
            dispatch(loader(false))
            return;
        }
    });
  };

  // onclick of submit and suggested feedback
  const updateFeedback = (feedback) => {
    let responseStatus = {
      feedback: feedback
    }
    handleLoader();
    likeordislike(responseStatus,(response) => {
    }, (err) => {
      setError(err.message);
      handleStopResponse();
    });
  }

  // onclick like/unlike
  const updateResponseStatus = (status) => {
    let responseStatus = {
      status: status,
    }
    handleLoader();
    likeordislike(responseStatus,(response) => {
    }, (err) => {
      setError(err.message);
      handleStopResponse();
    });
  }
 
  return (
    <div>
     <div className="d-flex flex-column">
      <div
        className={`homepage ${loading ? "homepageresize": ""} 
        ${(loading && isScreenResponsive) ? "res_homepageresize": isScreenResponsive ? "res_homepage" : ""}`}
        id="pageContainer"
      >
        <div className="page-container">
          <div className="chatwindow">
            {/* <ScrollToBottom className={ROOT_CSS} followButtonClassName="follow_btn" ref={timeOutRef} onScroll={handleUserScroll}> */}
            <ScrollToBottom className={ROOT_CSS} followButtonClassName="follow_btn" >
            <div className={`conversationlist chat_options ${isScreenResponsive && "res_conversationlist"} `}>
                <div className={`container container-fluid min-height px-0 px-md-3 ${isScreenResponsive && "res_min-height"}`}>
                  <div style={{height: "80px"}}></div>
                  {
                    conList.length > 0 && 
                      <div>
                        <MessageHistory shortName={shortName} isAnswerComplete={!isResponseComplete} onValueChange={handleValueChange} effects={isStreaming} error="" isStopResponseTriggered={isStopResponseTriggered}
                        seIsStopResponseTriggered={seIsStopResponseTriggered} />
                        {!loading && isApiError === false  ?
                          <div>
                             {responseStop === false && stopStreaming &&
                              <LikeAndDislike responseId={lastConvoId} sessionChange={sessionChangeStatus}/>
                             }
                            {/* {con && con.response?.responseType !== "Error" && con.showLikeDislike &&
                              <LikeAndDislike responseId={con.id} sessionChange={con.sessionChange} responseLikestatus={con.likeStatus} responseFeedBack={con.feedBack}/>
                            } */}
                          </div>
                        :
                          <div className="text-danger mt-2 mb-3">
                            {isApiError}
                          </div>
                        }
                      </div>
                  }
                  <div>
                  <div className={`${streamedResponse.current.responseType === "" && 'chat_bubble_spacing'}`}></div>
                     <div className={`${loading && streamedResponse.current.responseType !== "" && 'response_div_spacing'}`}></div>
                  </div>
              </div>
              {/* <div className="text-danger">{error && error}</div> */}
            {/* {!loading &&  */}
             <div className="d-block d-md-flex container-md justify-content-end prompts px-0 help-links ps-lg-3" >
              {error === '' && !loading ? (
              <div id="s-propts">
               {error === "" && (
                <div className="footer-tags d-flex promtsFade">
                  {
                    streamedResponse.current.suggestedPrompts &&
                    streamedResponse.current.suggestedPrompts.map((suggested, i) => (
                        <button
                          className={`footer_btn ${i===0 && 'ms-20'} ${i===2 && 'last-footer-btn me-20'}`}
                          key={i}
                          onClick={() =>{streaming(suggested)}}
                          disabled={loading}
                        >
                          {suggested}
                        </button>
                      ))
                  }
                </div>
              )}
              </div>
             ):null} 
            </div>
            {/* } */}
            </div>
            </ScrollToBottom>
         </div> 
        </div>
      </div>  
     </div>
       {loading  && (
        <div className="col-12 d-flex justify-content-center">
          <button className="d-flex stop_btn" onClick={() => handleStopResponse(true)}>
            <div className="d-flex align-items-center">
              <img
                src="/static/assets/stop_icon.png"
                className="stop-icon"
                alt="stop_icon"
              />
              <div className="foundersGroteskTextRegular font_12 ms-2 stop-text">
                Stop Responding
              </div>
            </div>
          </button>
        </div>
      )}
      <div className="footer-gradient"></div>
      <div className="footer_search">
        <div className="container footer-container">
          <div className="chatbox d-flex justify-content-center align-items-center position-relative">
            <textarea
              id="textarea_id"
              className="form-control form-focus chat"
              ref={inputRef}
              value={input}
              type="text"
              placeholder={isPlaceHolderVisible ? "Send a message" : ""}
              onFocus={handleFocus}
              // disabled={loading}
              onChange={(e) => onHandleInputChange(e)}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  streaming(input);
                  setHeight(false);
                  dispatch(stopStreaming(true))
                  setIsSendButtonDisabled(true)
                }
              }}
              />
            <div>
              <button
                className={`${isScreenResponsive ? "m-chat-arrow" : "ios-chat-arrow"} chat-arrow send-arrow-position 
                ${loading || isSendButtonDisabled ? "disabled" : "enabled"}`}
                onClick={() => {
                  dispatch(stopStreaming(true))
                  streaming(input);
                  setHeight(false);
                  setIsSendButtonDisabled(true)
                }}
                disabled={isSendButtonDisabled}
              >
                <img
                  src={
                    loading || isSendButtonDisabled
                      ? "/static/assets/arrowlong.svg"
                      : "/static/assets/bluearrow.svg"
                  }
                  height="36px"
                  alt="arrow"
                  width="36px"
                  className="arrow_long"
                />
              </button>
            </div>
            {/* )}   */}
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Chat;