export const endpoints = {
    CHAT_HISTORY: "chat/chathistory",
    SESSION_HISTORY:"chat/sessionHistory",
    ADD_TO_CHAT: "chat/addchat",
    LIKE_OR_DISLIKE: "chat/likeordislike",
    APP_FEEDBACK: "chat/applicationfeedback",
    APP_LEVEL_FEEDBACK :"chat/getapplevelfeedback",
    SAVE_APP_LEVEL_FEEDBACK : "chat/applevelfeedback",
    DELETE_CHAT: "chat/deletechat",
    CHAT_HISTORY_PREV: "chat/chathistoryprev",
    CHAT_HISTORY_NEXT: "chat/chathistorynext",
    UNIQUE_GROUP_SESSION_HISTORY : 'chat/groupsessionhistory',
    DELETE_CHAT_DATA : 'chat/deletechatdata',
    FETCH_CHAT: "",
    USER_SESSION : "user/userSession",
    ADD_PERMITTED_USERS : "/user/add_permitted_users",
    GET_PERMITTED_USERS : "/user/get_permitted_users",
    DELETE_PERMITTED_USERS : "/user/delete_permitted_users",
}